import { createHttpLink, from } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { provideApolloClient } from '@vue/apollo-composable';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const client = useApollo();

  // trigger the error hook on an error
  const errorLink = onError((err) => {
    nuxtApp.callHook('apollo:error', err);
  });

  const httpLink = createHttpLink({
    uri: process.client
      ? config.public.apollo.httpBrowserEndpoint
      : config.public.apollo.httpServerEndpoint,
  });

  client.clients.default.setLink(from([errorLink, httpLink]));

  provideApolloClient(client.clients.default);
});
