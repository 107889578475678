<template>
  <nuxt-loading-indicator />
  <nuxt-layout>
    <nuxt-page />
  </nuxt-layout>
</template>
<script setup lang="ts">
const { localeProperties } = useI18n();

useHead({
  htmlAttrs: {
    lang: localeProperties.value.iso,
  },
});

const route = useRoute();
useSeoMeta({
  robots: () => {
    if (Object.keys(route.query).length > 0) {
      return 'noindex';
    }

    return undefined;
  },
});
</script>
