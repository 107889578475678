import nuxt_payloads_bVwXOiquXk from "/opt/app-root/src/website/plugins/nuxt-payloads.ts";
import revive_payload_client_4sVQNw7RlN from "/opt/app-root/src/website/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/app-root/src/website/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/app-root/src/website/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/app-root/src/website/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/app-root/src/website/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/app-root/src/website/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/app-root/src/website/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/app-root/src/website/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_eTVJQYlCmx from "/opt/app-root/src/website/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import i18n_yfWm7jX06p from "/opt/app-root/src/website/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/app-root/src/website/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import apollo_1ILFkzpYSr from "/opt/app-root/src/website/plugins/apollo.ts";
import auth_keycloak_client_CA6rlOunv5 from "/opt/app-root/src/website/plugins/auth.keycloak.client.ts";
import cookiefirst_client_Ah3rpq2cJZ from "/opt/app-root/src/website/plugins/cookiefirst.client.ts";
import grpc_transport_6whvQYnEBY from "/opt/app-root/src/website/plugins/grpc.transport.ts";
import i18n_disable_CQfX0fjmJg from "/opt/app-root/src/website/plugins/i18n-disable.ts";
import latency_client_zNbbVaLhNE from "/opt/app-root/src/website/plugins/latency.client.ts";
import sentry_client_shVUlIjFLk from "/opt/app-root/src/website/plugins/sentry.client.ts";
export default [
  nuxt_payloads_bVwXOiquXk,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_eTVJQYlCmx,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  apollo_1ILFkzpYSr,
  auth_keycloak_client_CA6rlOunv5,
  cookiefirst_client_Ah3rpq2cJZ,
  grpc_transport_6whvQYnEBY,
  i18n_disable_CQfX0fjmJg,
  latency_client_zNbbVaLhNE,
  sentry_client_shVUlIjFLk
]