// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file gportal/type/v1/money.proto (package gportal.type.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

/**
 * @generated from message gportal.type.v1.Money
 */
export class Money extends Message<Money> {
  /**
   * Amount in subunits of the currency.
   *
   * @generated from field: int64 amount = 1;
   */
  amount = protoInt64.zero;

  /**
   * Currency code in ISO 4217 format.
   *
   * @generated from field: string currency_code = 2;
   */
  currencyCode = "";

  /**
   * Number of fractional digits for the currency.
   *
   * @generated from field: int32 fraction = 3;
   */
  fraction = 0;

  /**
   * @generated from field: gportal.type.v1.MoneyDisplay display = 4;
   */
  display?: MoneyDisplay;

  constructor(data?: PartialMessage<Money>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.type.v1.Money";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "currency_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "fraction", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "display", kind: "message", T: MoneyDisplay },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Money {
    return new Money().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Money {
    return new Money().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Money {
    return new Money().fromJsonString(jsonString, options);
  }

  static equals(a: Money | PlainMessage<Money> | undefined, b: Money | PlainMessage<Money> | undefined): boolean {
    return proto3.util.equals(Money, a, b);
  }
}

/**
 * @generated from message gportal.type.v1.MoneyDisplay
 */
export class MoneyDisplay extends Message<MoneyDisplay> {
  /**
   * @generated from field: string display = 1;
   */
  display = "";

  /**
   * @generated from field: string locale = 2;
   */
  locale = "";

  constructor(data?: PartialMessage<MoneyDisplay>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.type.v1.MoneyDisplay";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "display", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "locale", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MoneyDisplay {
    return new MoneyDisplay().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MoneyDisplay {
    return new MoneyDisplay().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MoneyDisplay {
    return new MoneyDisplay().fromJsonString(jsonString, options);
  }

  static equals(a: MoneyDisplay | PlainMessage<MoneyDisplay> | undefined, b: MoneyDisplay | PlainMessage<MoneyDisplay> | undefined): boolean {
    return proto3.util.equals(MoneyDisplay, a, b);
  }
}

