// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file gportal/api/user/v1/rpc.proto (package gportal.api.user.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ChangeCurrencyRequest, ChangePreferredRegionRequest, CreateCustomerRequest, GetCustomerRequest, GetProfileRequest, GetUserRequest, NewsletterOptInRequest, NewsletterOptOutRequest, RegisterUserRequest, RegisterUserRequirementsRequest, ResendVerificationEmailRequest, VerifyEmailRequest } from "./requests_pb.js";
import { ChangeCurrencyResponse, ChangePreferredRegionResponse, CreateCustomerResponse, GetCustomerResponse, GetProfileResponse, GetUserResponse, NewsletterOptInResponse, NewsletterOptOutResponse, RegisterUserRequirementsResponse, RegisterUserResponse, ResendVerificationEmailResponse, VerifyEmailResponse } from "./responses_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service gportal.api.user.v1.UserService
 */
export const UserService = {
  typeName: "gportal.api.user.v1.UserService",
  methods: {
    /**
     * @generated from rpc gportal.api.user.v1.UserService.RegisterUser
     */
    registerUser: {
      name: "RegisterUser",
      I: RegisterUserRequest,
      O: RegisterUserResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc gportal.api.user.v1.UserService.RegisterUserRequirements
     */
    registerUserRequirements: {
      name: "RegisterUserRequirements",
      I: RegisterUserRequirementsRequest,
      O: RegisterUserRequirementsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc gportal.api.user.v1.UserService.ResendVerificationEmail
     */
    resendVerificationEmail: {
      name: "ResendVerificationEmail",
      I: ResendVerificationEmailRequest,
      O: ResendVerificationEmailResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc gportal.api.user.v1.UserService.VerifyEmail
     */
    verifyEmail: {
      name: "VerifyEmail",
      I: VerifyEmailRequest,
      O: VerifyEmailResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Returns basic user information.
     *
     * @generated from rpc gportal.api.user.v1.UserService.GetUser
     */
    getUser: {
      name: "GetUser",
      I: GetUserRequest,
      O: GetUserResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Returns extended profile information.
     *
     * @generated from rpc gportal.api.user.v1.UserService.GetProfile
     */
    getProfile: {
      name: "GetProfile",
      I: GetProfileRequest,
      O: GetProfileResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Returns the GPORTAL customer.
     *
     * @generated from rpc gportal.api.user.v1.UserService.GetCustomer
     */
    getCustomer: {
      name: "GetCustomer",
      I: GetCustomerRequest,
      O: GetCustomerResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Create the GPORTAL customer.
     *
     * @generated from rpc gportal.api.user.v1.UserService.CreateCustomer
     */
    createCustomer: {
      name: "CreateCustomer",
      I: CreateCustomerRequest,
      O: CreateCustomerResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc gportal.api.user.v1.UserService.ChangePreferredRegion
     */
    changePreferredRegion: {
      name: "ChangePreferredRegion",
      I: ChangePreferredRegionRequest,
      O: ChangePreferredRegionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc gportal.api.user.v1.UserService.ChangeCurrency
     */
    changeCurrency: {
      name: "ChangeCurrency",
      I: ChangeCurrencyRequest,
      O: ChangeCurrencyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc gportal.api.user.v1.UserService.NewsletterOptIn
     */
    newsletterOptIn: {
      name: "NewsletterOptIn",
      I: NewsletterOptInRequest,
      O: NewsletterOptInResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc gportal.api.user.v1.UserService.NewsletterOptOut
     */
    newsletterOptOut: {
      name: "NewsletterOptOut",
      I: NewsletterOptOutRequest,
      O: NewsletterOptOutResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

