<template>
  <svg
    class="svg-inline--fa"
    :class="`fa-${iconName}`"
    aria-hidden="true"
    focusable="false"
    :data-prefix="iconPrefix"
    :data-icon="iconName"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="`0 0 ${width} ${height}`"
    v-bind="attrs"
  >
    <g v-if="Array.isArray(pathData)">
      <path
        v-for="(d, index) in pathData"
        :key="index"
        fill="currentColor"
        :d="d"
      />
    </g>
    <path v-else class="" fill="currentColor" :d="pathData" />
  </svg>
</template>

<script setup lang="ts">
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const props = defineProps<{
  icon: object | IconDefinition;
}>();

const [width, height, ligatures, unicode, pathData] = (
  props.icon as IconDefinition
).icon;

const attrs = useAttrs();
const iconPrefix = (props.icon as IconDefinition).prefix;
const iconName = (props.icon as IconDefinition).iconName;
</script>

<style scoped></style>
