import { UserService } from 'gportal-grpc/gportal/api/user/v1/rpc_connect';
import UniversalGrpcClient from '~/plugins/grpc/universal/UniversalGrpcClient';
import { GpRegion } from '~/plugins/grpc/universal/types';
import { getSecondaryRegion } from '~/plugins/grpc/universal/utils';

export default () => {
  const { $primaryTransport, $secondaryTransport } = useNuxtApp();
  const { public: publicConfig } = useRuntimeConfig();
  const { grpc } = publicConfig;
  const secondaryRegion = getSecondaryRegion(grpc.primaryRegion as GpRegion);

  return new UniversalGrpcClient(UserService, {
    primaryRegion: grpc.primaryRegion as GpRegion,
    transports: {
      [grpc.primaryRegion]: $primaryTransport,
      [secondaryRegion]: $secondaryTransport,
    },
  });
};
