
// @ts-nocheck


export const localeCodes =  [
  "de",
  "en",
  "fr",
  "it",
  "pl",
  "es",
  "pt",
  "nl",
  "ja",
  "zh"
]

export const localeLoaders = {
  "de": [{ key: "../locales/de.ts", load: () => import("../locales/de.ts" /* webpackChunkName: "locale__opt_app_root_src_website_locales_de_ts" */), cache: true }],
  "en": [{ key: "../locales/en.ts", load: () => import("../locales/en.ts" /* webpackChunkName: "locale__opt_app_root_src_website_locales_en_ts" */), cache: true }],
  "fr": [{ key: "../locales/fr.ts", load: () => import("../locales/fr.ts" /* webpackChunkName: "locale__opt_app_root_src_website_locales_fr_ts" */), cache: true }],
  "it": [{ key: "../locales/it.ts", load: () => import("../locales/it.ts" /* webpackChunkName: "locale__opt_app_root_src_website_locales_it_ts" */), cache: true }],
  "pl": [{ key: "../locales/pl.ts", load: () => import("../locales/pl.ts" /* webpackChunkName: "locale__opt_app_root_src_website_locales_pl_ts" */), cache: true }],
  "es": [{ key: "../locales/es.ts", load: () => import("../locales/es.ts" /* webpackChunkName: "locale__opt_app_root_src_website_locales_es_ts" */), cache: true }],
  "pt": [{ key: "../locales/pt.ts", load: () => import("../locales/pt.ts" /* webpackChunkName: "locale__opt_app_root_src_website_locales_pt_ts" */), cache: true }],
  "nl": [{ key: "../locales/nl.ts", load: () => import("../locales/nl.ts" /* webpackChunkName: "locale__opt_app_root_src_website_locales_nl_ts" */), cache: true }],
  "ja": [{ key: "../locales/ja.ts", load: () => import("../locales/ja.ts" /* webpackChunkName: "locale__opt_app_root_src_website_locales_ja_ts" */), cache: true }],
  "zh": [{ key: "../locales/zh.ts", load: () => import("../locales/zh.ts" /* webpackChunkName: "locale__opt_app_root_src_website_locales_zh_ts" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "i18n_config_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "de",
      "currency": "EUR",
      "iso": "de-DE",
      "displayLanguage": "Deutsch",
      "defaultServerLocation": "fra01",
      "files": [
        "locales/de.ts"
      ]
    },
    {
      "code": "en",
      "currency": "USD",
      "iso": "en-US",
      "displayLanguage": "English",
      "defaultServerLocation": "iad01",
      "files": [
        "locales/en.ts"
      ]
    },
    {
      "code": "fr",
      "currency": "EUR",
      "iso": "fr-FR",
      "displayLanguage": "Français",
      "defaultServerLocation": "fra01",
      "files": [
        "locales/fr.ts"
      ]
    },
    {
      "code": "it",
      "currency": "EUR",
      "iso": "it-IT",
      "displayLanguage": "Italiano",
      "defaultServerLocation": "fra01",
      "files": [
        "locales/it.ts"
      ]
    },
    {
      "code": "pl",
      "currency": "EUR",
      "iso": "pl-PL",
      "displayLanguage": "Polski",
      "defaultServerLocation": "fra01",
      "files": [
        "locales/pl.ts"
      ]
    },
    {
      "code": "es",
      "currency": "EUR",
      "iso": "es-ES",
      "displayLanguage": "Español",
      "defaultServerLocation": "fra01",
      "files": [
        "locales/es.ts"
      ]
    },
    {
      "code": "pt",
      "currency": "EUR",
      "iso": "pt-PT",
      "displayLanguage": "Português",
      "defaultServerLocation": "fra01",
      "files": [
        "locales/pt.ts"
      ]
    },
    {
      "code": "nl",
      "currency": "EUR",
      "iso": "nl-NL",
      "displayLanguage": "Nederlands",
      "defaultServerLocation": "fra01",
      "files": [
        "locales/nl.ts"
      ]
    },
    {
      "code": "ja",
      "currency": "JPY",
      "iso": "ja-JP",
      "displayLanguage": "日本語",
      "defaultServerLocation": "hnd01",
      "files": [
        "locales/ja.ts"
      ]
    },
    {
      "code": "zh",
      "currency": "USD",
      "iso": "zh-CN",
      "displayLanguage": "中文",
      "defaultServerLocation": "tpe01",
      "files": [
        "locales/zh.ts"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": true,
  "langDir": "locales/",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "https://www.g-portal.com",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "de",
    "currency": "EUR",
    "iso": "de-DE",
    "displayLanguage": "Deutsch",
    "defaultServerLocation": "fra01",
    "files": [
      {
        "path": "locales/de.ts"
      }
    ]
  },
  {
    "code": "en",
    "currency": "USD",
    "iso": "en-US",
    "displayLanguage": "English",
    "defaultServerLocation": "iad01",
    "files": [
      {
        "path": "locales/en.ts"
      }
    ]
  },
  {
    "code": "fr",
    "currency": "EUR",
    "iso": "fr-FR",
    "displayLanguage": "Français",
    "defaultServerLocation": "fra01",
    "files": [
      {
        "path": "locales/fr.ts"
      }
    ]
  },
  {
    "code": "it",
    "currency": "EUR",
    "iso": "it-IT",
    "displayLanguage": "Italiano",
    "defaultServerLocation": "fra01",
    "files": [
      {
        "path": "locales/it.ts"
      }
    ]
  },
  {
    "code": "pl",
    "currency": "EUR",
    "iso": "pl-PL",
    "displayLanguage": "Polski",
    "defaultServerLocation": "fra01",
    "files": [
      {
        "path": "locales/pl.ts"
      }
    ]
  },
  {
    "code": "es",
    "currency": "EUR",
    "iso": "es-ES",
    "displayLanguage": "Español",
    "defaultServerLocation": "fra01",
    "files": [
      {
        "path": "locales/es.ts"
      }
    ]
  },
  {
    "code": "pt",
    "currency": "EUR",
    "iso": "pt-PT",
    "displayLanguage": "Português",
    "defaultServerLocation": "fra01",
    "files": [
      {
        "path": "locales/pt.ts"
      }
    ]
  },
  {
    "code": "nl",
    "currency": "EUR",
    "iso": "nl-NL",
    "displayLanguage": "Nederlands",
    "defaultServerLocation": "fra01",
    "files": [
      {
        "path": "locales/nl.ts"
      }
    ]
  },
  {
    "code": "ja",
    "currency": "JPY",
    "iso": "ja-JP",
    "displayLanguage": "日本語",
    "defaultServerLocation": "hnd01",
    "files": [
      {
        "path": "locales/ja.ts"
      }
    ]
  },
  {
    "code": "zh",
    "currency": "USD",
    "iso": "zh-CN",
    "displayLanguage": "中文",
    "defaultServerLocation": "tpe01",
    "files": [
      {
        "path": "locales/zh.ts"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
