import { GP_ACCESS_TOKEN_COOKIE } from './constants';
import { Scope } from '@sentry/vue';
import { Code, type ConnectError } from '@connectrpc/connect';
import type { GrpcError } from '../grpc/universal/types';
import type { KeycloakAuth } from '@gportal/keycloak-auth';

/**
 * Returns the Keycloak Auth instance and a logout function.
 *
 * Only use this composable in the auth plugin and only, if you are sure, that the user is authenticated.
 *
 * Otherwise, this composable will import the heavy keycloak auth library and initialize it which is not desired for unauthenticated users.
 */
export default async (onAccessTokenChange = () => {}) => {
  const { KeycloakAuth } = await import('@gportal/keycloak-auth');

  const authStore = useAuthStore();
  const oidcStore = useOidcStore();
  const config = useRuntimeConfig();
  const { $sentry, $i18n } = useNuxtApp();
  const tokenCookie = useCookie(GP_ACCESS_TOKEN_COOKIE);

  const auth = new KeycloakAuth(
    {
      url: config.public.auth.url,
      realm: config.public.auth.realm,
      clientId: config.public.auth.clientId,
      accessTokenCookieName: GP_ACCESS_TOKEN_COOKIE,
      autoRefresh: true,
      syncToken: true,
    },
    true,
  );

  auth.onCapturedException = (error) => {
    $sentry.captureException(error);
  };

  auth.onCapturedMessage = (message, args) => {
    if (args != null) {
      const scope = new Scope();
      Object.keys(args).forEach((key) => {
        scope.setExtra(key, args[key]);
      });
      $sentry.captureMessage(message, scope);
      return;
    }

    $sentry.captureMessage(message);
  };

  const logout = async () => {
    if (auth.storedTokenSet == null || auth.storedTokenSet.id_token == null) {
      authStore.user = null;
      return;
    }

    const keycloakOIDCUrl = `${config.public.auth.url}/realms/${config.public.auth.realm}/protocol/openid-connect`;
    const url =
      `${keycloakOIDCUrl}/logout` +
      `?post_logout_redirect_uri=${encodeURIComponent(
        `${config.public.websiteUrl}/${$i18n.locale.value}`,
      )}&id_token_hint=${encodeURIComponent(auth.storedTokenSet.id_token)}`;

    // force removal of access token from the cookies, so after the redirect from keycloak to nuxt,
    // we do not want the server to fetch the user data and sets the store
    tokenCookie.value = null;
    auth.clearTokenStorage();
    await navigateTo(url, {
      external: true,
    });
  };

  // if we have an error on server side, then we are currently at the callback page
  // if we have a unauthenticated error (grpc-status 16), the token may be invalid - let keycloak restore the token
  // if we have an unknown error, logout
  if (
    authStore.serviceError != null &&
    (authStore.serviceError as unknown as ConnectError).code !==
      Code.Unauthenticated
  ) {
    logout();
    return {
      provide: {
        auth: null as KeycloakAuth | null,
      },
    };
  }

  auth.onAccessTokenChange = async () => {
    refreshCookie(GP_ACCESS_TOKEN_COOKIE);
    oidcStore.setTokens({
      accessToken:
        auth.storedTokenSet?.access_token || auth.accessToken || undefined,
      refreshToken: auth.storedTokenSet?.refresh_token || undefined,
      idToken: auth.storedTokenSet?.id_token || undefined,
    });

    onAccessTokenChange();
  };

  const route = useRoute();
  auth.onAuthStatusChange = async () => {
    refreshCookie(GP_ACCESS_TOKEN_COOKIE);
    // if the user is authenticated and loaded on server side, then the fetch for user info is not necessary
    if (authStore.isAuthenticated && !authStore.isUserLoading) {
      // we have to remove the preferred and initialize with active customer currency
      localStorage.removeItem(USER_CURRENCY_STORE_KEY);
      return;
    }

    if (auth.isAuthenticated) {
      // register page has an own handler
      if (route.path.endsWith('/register')) return;

      try {
        await authStore.fetchUser();
        await authStore.fetchCustomers();
        authStore.isAuthenticated = auth.isAuthenticated;
        authStore.serviceError = null;
        // also here we have to remove the set the guest currency (when coming from anonymous) and initialize with active customer currency
        localStorage.removeItem(USER_CURRENCY_STORE_KEY);
      } catch (error) {
        if (error satisfies GrpcError) {
          $sentry.captureException(error, {
            extra: {
              code: error.code,
              message: error.message,
            },
          });
        } else {
          $sentry.captureException(error);
        }

        // if on the frontend side, the fetch for user and customer fails for any reason
        // -> force logout
        logout();
      }
    }
  };

  return {
    auth,
    logout,
  };
};
