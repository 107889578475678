export const useOidcStore = defineStore('oidc', () => {
  const state = reactive({
    accessToken: undefined as string | undefined,
    refreshToken: undefined as string | undefined,
    idToken: undefined as string | undefined,
  });

  const setTokens = ({
    accessToken,
    refreshToken,
    idToken,
  }: {
    accessToken: string;
    refreshToken: string;
    idToken: string;
  }) => {
    state.accessToken = accessToken;
    state.refreshToken = refreshToken;
    state.idToken = idToken;
  };

  const resetTokens = () => {
    state.accessToken = undefined;
    state.refreshToken = undefined;
    state.idToken = undefined;
  };

  return {
    ...toRefs(state),
    setTokens,
    resetTokens,
  };
});
