// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file gportal/type/v1/accesstoken.proto (package gportal.type.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message gportal.type.v1.AccessToken
 */
export class AccessToken extends Message<AccessToken> {
  /**
   * @generated from field: string access_token = 1;
   */
  accessToken = "";

  /**
   * @generated from field: string id_token = 2;
   */
  idToken = "";

  /**
   * @generated from field: string refresh_token = 3;
   */
  refreshToken = "";

  constructor(data?: PartialMessage<AccessToken>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.type.v1.AccessToken";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "access_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "refresh_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AccessToken {
    return new AccessToken().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AccessToken {
    return new AccessToken().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AccessToken {
    return new AccessToken().fromJsonString(jsonString, options);
  }

  static equals(a: AccessToken | PlainMessage<AccessToken> | undefined, b: AccessToken | PlainMessage<AccessToken> | undefined): boolean {
    return proto3.util.equals(AccessToken, a, b);
  }
}

