import { defineNuxtPlugin } from '#app';

export default defineNuxtPlugin(() => {
  const { $i18n } = useNuxtApp();
  const { cookieFirst } = useAppConfig();

  let loaded = false;

  function loadScript() {
    if (!loaded) {
      loaded = true;

      const script = document.createElement('script');
      script.src = 'https://consent.cookiefirst.com/banner.js';
      script.dataset.cookiefirstKey = cookieFirst.key;
      script.dataset.language = $i18n.locale.value;
      script.dataset.silentMode = 'true';
      document.head.appendChild(script);
    }
  }

  // We need to wait for the Nuxt app to be ready, then we wait for the user to
  // interact with the page before loading the CookieFirst script. This is
  // because we don't want to show the banner to bots or crawlers (e.g. Google)
  onNuxtReady(() => {
    if (navigator.userAgent.indexOf('CookieCheck') !== -1) {
      loadScript();
    } else {
      document.addEventListener('mousemove', loadScript, { once: true });
      document.addEventListener('click', loadScript, { once: true });
      document.addEventListener('scroll', loadScript, {
        once: true,
        passive: true,
      });
    }
  });
});
