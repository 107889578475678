import type { Code, ConnectError } from '@connectrpc/connect';
import { type PlainMessage, type AnyMessage } from '@bufbuild/protobuf';

export enum GpRegion {
  EUR = 'EUR',
  INT = 'INT',
}

export type IncomingDetail = {
  type: string;
  value: Uint8Array;
};

export type GrpcError = {
  code: Code;
  message: string;
  details?: IncomingDetail[];
};

export type GrpcResponse<O extends PlainMessage<AnyMessage>> = {
  response: O;
  error: GrpcError;
};

export type GrpcRegionMergedData<O> = {
  [key in GpRegion]: O;
};

export type GrpcRegionMergedResponse<O> = {
  [key in GpRegion]: GrpcResponse<O>;
};

export type EndpointsParam = {
  [key in GpRegion]: string;
};
