import { defineStore } from 'pinia';

export type NotificationTypes = 'error' | 'success' | 'warning';

export type Notification = {
  id: number;
  title: string;
  message?: string;
  type?: NotificationTypes;
  timeout?: number;
  dismissedCallback?: () => Promise<void> | void;
};

export const useNotificationsStore = defineStore({
  id: 'notifications',
  state: () => ({
    notifications: [] as Array<Notification>,
  }),
  getters: {
    sortedNotifications: (state) =>
      new Array<Notification>(...state.notifications).reverse(),
  },
  actions: {
    pushNotification(notification: Notification) {
      this.notifications.push(notification);
    },
    removeNotification(notification: Notification | number) {
      const id =
        typeof notification === 'number' ? notification : notification.id;
      this.notifications = this.notifications.filter((n) => n.id !== id);
    },
  },
});
