// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file gportal/api/user/v1/responses.proto (package gportal.api.user.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { User, UserProfile } from "./user_pb.js";
import { Customer } from "./customer_pb.js";
import { AccessToken } from "../../../type/v1/accesstoken_pb.js";
import { Money } from "../../../type/v1/money_pb.js";

/**
 * @generated from message gportal.api.user.v1.GetUserResponse
 */
export class GetUserResponse extends Message<GetUserResponse> {
  /**
   * @generated from field: gportal.api.user.v1.User user = 1;
   */
  user?: User;

  constructor(data?: PartialMessage<GetUserResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.GetUserResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user", kind: "message", T: User },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUserResponse {
    return new GetUserResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUserResponse {
    return new GetUserResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUserResponse {
    return new GetUserResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetUserResponse | PlainMessage<GetUserResponse> | undefined, b: GetUserResponse | PlainMessage<GetUserResponse> | undefined): boolean {
    return proto3.util.equals(GetUserResponse, a, b);
  }
}

/**
 * @generated from message gportal.api.user.v1.GetCustomerResponse
 */
export class GetCustomerResponse extends Message<GetCustomerResponse> {
  /**
   * @generated from field: gportal.api.user.v1.Customer customer = 1;
   */
  customer?: Customer;

  constructor(data?: PartialMessage<GetCustomerResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.GetCustomerResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "customer", kind: "message", T: Customer },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCustomerResponse {
    return new GetCustomerResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCustomerResponse {
    return new GetCustomerResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCustomerResponse {
    return new GetCustomerResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCustomerResponse | PlainMessage<GetCustomerResponse> | undefined, b: GetCustomerResponse | PlainMessage<GetCustomerResponse> | undefined): boolean {
    return proto3.util.equals(GetCustomerResponse, a, b);
  }
}

/**
 * @generated from message gportal.api.user.v1.CreateCustomerResponse
 */
export class CreateCustomerResponse extends Message<CreateCustomerResponse> {
  /**
   * @generated from field: gportal.api.user.v1.Customer customer = 1;
   */
  customer?: Customer;

  constructor(data?: PartialMessage<CreateCustomerResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.CreateCustomerResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "customer", kind: "message", T: Customer },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateCustomerResponse {
    return new CreateCustomerResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateCustomerResponse {
    return new CreateCustomerResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateCustomerResponse {
    return new CreateCustomerResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateCustomerResponse | PlainMessage<CreateCustomerResponse> | undefined, b: CreateCustomerResponse | PlainMessage<CreateCustomerResponse> | undefined): boolean {
    return proto3.util.equals(CreateCustomerResponse, a, b);
  }
}

/**
 * @generated from message gportal.api.user.v1.RegisterUserResponse
 */
export class RegisterUserResponse extends Message<RegisterUserResponse> {
  /**
   * @generated from field: gportal.api.user.v1.User user = 1;
   */
  user?: User;

  /**
   * @generated from field: gportal.type.v1.AccessToken access_token = 2;
   */
  accessToken?: AccessToken;

  constructor(data?: PartialMessage<RegisterUserResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.RegisterUserResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user", kind: "message", T: User },
    { no: 2, name: "access_token", kind: "message", T: AccessToken },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RegisterUserResponse {
    return new RegisterUserResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RegisterUserResponse {
    return new RegisterUserResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RegisterUserResponse {
    return new RegisterUserResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RegisterUserResponse | PlainMessage<RegisterUserResponse> | undefined, b: RegisterUserResponse | PlainMessage<RegisterUserResponse> | undefined): boolean {
    return proto3.util.equals(RegisterUserResponse, a, b);
  }
}

/**
 * @generated from message gportal.api.user.v1.RegisterUserRequirementsResponse
 */
export class RegisterUserRequirementsResponse extends Message<RegisterUserRequirementsResponse> {
  /**
   * @generated from field: repeated gportal.api.user.v1.RegisterUserRequirementsResponseCountryOption country_options = 1;
   */
  countryOptions: RegisterUserRequirementsResponseCountryOption[] = [];

  /**
   * @generated from field: repeated string countries_requiring_postal_code = 2;
   */
  countriesRequiringPostalCode: string[] = [];

  /**
   * @generated from field: repeated string countries_requiring_full_address = 3;
   */
  countriesRequiringFullAddress: string[] = [];

  constructor(data?: PartialMessage<RegisterUserRequirementsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.RegisterUserRequirementsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "country_options", kind: "message", T: RegisterUserRequirementsResponseCountryOption, repeated: true },
    { no: 2, name: "countries_requiring_postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "countries_requiring_full_address", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RegisterUserRequirementsResponse {
    return new RegisterUserRequirementsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RegisterUserRequirementsResponse {
    return new RegisterUserRequirementsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RegisterUserRequirementsResponse {
    return new RegisterUserRequirementsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RegisterUserRequirementsResponse | PlainMessage<RegisterUserRequirementsResponse> | undefined, b: RegisterUserRequirementsResponse | PlainMessage<RegisterUserRequirementsResponse> | undefined): boolean {
    return proto3.util.equals(RegisterUserRequirementsResponse, a, b);
  }
}

/**
 * @generated from message gportal.api.user.v1.GetProfileResponse
 */
export class GetProfileResponse extends Message<GetProfileResponse> {
  /**
   * @generated from field: gportal.api.user.v1.UserProfile profile = 1;
   */
  profile?: UserProfile;

  constructor(data?: PartialMessage<GetProfileResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.GetProfileResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "profile", kind: "message", T: UserProfile },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProfileResponse {
    return new GetProfileResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProfileResponse {
    return new GetProfileResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProfileResponse {
    return new GetProfileResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetProfileResponse | PlainMessage<GetProfileResponse> | undefined, b: GetProfileResponse | PlainMessage<GetProfileResponse> | undefined): boolean {
    return proto3.util.equals(GetProfileResponse, a, b);
  }
}

/**
 * @generated from message gportal.api.user.v1.RegisterUserRequirementsResponseCountryOption
 */
export class RegisterUserRequirementsResponseCountryOption extends Message<RegisterUserRequirementsResponseCountryOption> {
  /**
   * @generated from field: string code = 1;
   */
  code = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  constructor(data?: PartialMessage<RegisterUserRequirementsResponseCountryOption>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.RegisterUserRequirementsResponseCountryOption";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RegisterUserRequirementsResponseCountryOption {
    return new RegisterUserRequirementsResponseCountryOption().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RegisterUserRequirementsResponseCountryOption {
    return new RegisterUserRequirementsResponseCountryOption().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RegisterUserRequirementsResponseCountryOption {
    return new RegisterUserRequirementsResponseCountryOption().fromJsonString(jsonString, options);
  }

  static equals(a: RegisterUserRequirementsResponseCountryOption | PlainMessage<RegisterUserRequirementsResponseCountryOption> | undefined, b: RegisterUserRequirementsResponseCountryOption | PlainMessage<RegisterUserRequirementsResponseCountryOption> | undefined): boolean {
    return proto3.util.equals(RegisterUserRequirementsResponseCountryOption, a, b);
  }
}

/**
 * @generated from message gportal.api.user.v1.VerifyEmailResponse
 */
export class VerifyEmailResponse extends Message<VerifyEmailResponse> {
  constructor(data?: PartialMessage<VerifyEmailResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.VerifyEmailResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VerifyEmailResponse {
    return new VerifyEmailResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VerifyEmailResponse {
    return new VerifyEmailResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VerifyEmailResponse {
    return new VerifyEmailResponse().fromJsonString(jsonString, options);
  }

  static equals(a: VerifyEmailResponse | PlainMessage<VerifyEmailResponse> | undefined, b: VerifyEmailResponse | PlainMessage<VerifyEmailResponse> | undefined): boolean {
    return proto3.util.equals(VerifyEmailResponse, a, b);
  }
}

/**
 * @generated from message gportal.api.user.v1.ResendVerificationEmailResponse
 */
export class ResendVerificationEmailResponse extends Message<ResendVerificationEmailResponse> {
  constructor(data?: PartialMessage<ResendVerificationEmailResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.ResendVerificationEmailResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResendVerificationEmailResponse {
    return new ResendVerificationEmailResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResendVerificationEmailResponse {
    return new ResendVerificationEmailResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResendVerificationEmailResponse {
    return new ResendVerificationEmailResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ResendVerificationEmailResponse | PlainMessage<ResendVerificationEmailResponse> | undefined, b: ResendVerificationEmailResponse | PlainMessage<ResendVerificationEmailResponse> | undefined): boolean {
    return proto3.util.equals(ResendVerificationEmailResponse, a, b);
  }
}

/**
 * @generated from message gportal.api.user.v1.ChangePreferredRegionResponse
 */
export class ChangePreferredRegionResponse extends Message<ChangePreferredRegionResponse> {
  constructor(data?: PartialMessage<ChangePreferredRegionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.ChangePreferredRegionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChangePreferredRegionResponse {
    return new ChangePreferredRegionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChangePreferredRegionResponse {
    return new ChangePreferredRegionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChangePreferredRegionResponse {
    return new ChangePreferredRegionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ChangePreferredRegionResponse | PlainMessage<ChangePreferredRegionResponse> | undefined, b: ChangePreferredRegionResponse | PlainMessage<ChangePreferredRegionResponse> | undefined): boolean {
    return proto3.util.equals(ChangePreferredRegionResponse, a, b);
  }
}

/**
 * @generated from message gportal.api.user.v1.ChangeLocaleResponse
 */
export class ChangeLocaleResponse extends Message<ChangeLocaleResponse> {
  constructor(data?: PartialMessage<ChangeLocaleResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.ChangeLocaleResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChangeLocaleResponse {
    return new ChangeLocaleResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChangeLocaleResponse {
    return new ChangeLocaleResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChangeLocaleResponse {
    return new ChangeLocaleResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ChangeLocaleResponse | PlainMessage<ChangeLocaleResponse> | undefined, b: ChangeLocaleResponse | PlainMessage<ChangeLocaleResponse> | undefined): boolean {
    return proto3.util.equals(ChangeLocaleResponse, a, b);
  }
}

/**
 * @generated from message gportal.api.user.v1.ChangeCurrencyResponse
 */
export class ChangeCurrencyResponse extends Message<ChangeCurrencyResponse> {
  /**
   * @generated from field: gportal.type.v1.Money wallet = 1;
   */
  wallet?: Money;

  constructor(data?: PartialMessage<ChangeCurrencyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.ChangeCurrencyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "wallet", kind: "message", T: Money },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChangeCurrencyResponse {
    return new ChangeCurrencyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChangeCurrencyResponse {
    return new ChangeCurrencyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChangeCurrencyResponse {
    return new ChangeCurrencyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ChangeCurrencyResponse | PlainMessage<ChangeCurrencyResponse> | undefined, b: ChangeCurrencyResponse | PlainMessage<ChangeCurrencyResponse> | undefined): boolean {
    return proto3.util.equals(ChangeCurrencyResponse, a, b);
  }
}

/**
 * @generated from message gportal.api.user.v1.ChangeTimezoneResponse
 */
export class ChangeTimezoneResponse extends Message<ChangeTimezoneResponse> {
  constructor(data?: PartialMessage<ChangeTimezoneResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.ChangeTimezoneResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChangeTimezoneResponse {
    return new ChangeTimezoneResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChangeTimezoneResponse {
    return new ChangeTimezoneResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChangeTimezoneResponse {
    return new ChangeTimezoneResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ChangeTimezoneResponse | PlainMessage<ChangeTimezoneResponse> | undefined, b: ChangeTimezoneResponse | PlainMessage<ChangeTimezoneResponse> | undefined): boolean {
    return proto3.util.equals(ChangeTimezoneResponse, a, b);
  }
}

/**
 * @generated from message gportal.api.user.v1.NewsletterOptInResponse
 */
export class NewsletterOptInResponse extends Message<NewsletterOptInResponse> {
  constructor(data?: PartialMessage<NewsletterOptInResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.NewsletterOptInResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NewsletterOptInResponse {
    return new NewsletterOptInResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NewsletterOptInResponse {
    return new NewsletterOptInResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NewsletterOptInResponse {
    return new NewsletterOptInResponse().fromJsonString(jsonString, options);
  }

  static equals(a: NewsletterOptInResponse | PlainMessage<NewsletterOptInResponse> | undefined, b: NewsletterOptInResponse | PlainMessage<NewsletterOptInResponse> | undefined): boolean {
    return proto3.util.equals(NewsletterOptInResponse, a, b);
  }
}

/**
 * @generated from message gportal.api.user.v1.NewsletterOptOutResponse
 */
export class NewsletterOptOutResponse extends Message<NewsletterOptOutResponse> {
  constructor(data?: PartialMessage<NewsletterOptOutResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.NewsletterOptOutResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NewsletterOptOutResponse {
    return new NewsletterOptOutResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NewsletterOptOutResponse {
    return new NewsletterOptOutResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NewsletterOptOutResponse {
    return new NewsletterOptOutResponse().fromJsonString(jsonString, options);
  }

  static equals(a: NewsletterOptOutResponse | PlainMessage<NewsletterOptOutResponse> | undefined, b: NewsletterOptOutResponse | PlainMessage<NewsletterOptOutResponse> | undefined): boolean {
    return proto3.util.equals(NewsletterOptOutResponse, a, b);
  }
}

