// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file gportal/api/user/v1/user.proto (package gportal.api.user.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { Region } from "../../../type/v1/region_pb.js";
import { AuthenticationType, TwoFactorType } from "./authentication_pb.js";

/**
 * @generated from message gportal.api.user.v1.User
 */
export class User extends Message<User> {
  /**
   * Keycloak User ID
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * Keycloak Username
   *
   * @generated from field: optional string username = 2;
   */
  username?: string;

  /**
   * Keycloak E-Mail
   *
   * @generated from field: optional string email = 3;
   */
  email?: string;

  /**
   * @generated from field: optional bool email_verified = 4;
   */
  emailVerified?: boolean;

  /**
   * Keycloak First Name and Last Name
   *
   * @generated from field: optional string first_name = 5;
   */
  firstName?: string;

  /**
   * @generated from field: optional string last_name = 6;
   */
  lastName?: string;

  /**
   * Optional address
   *
   * @generated from field: optional gportal.api.user.v1.UserAddress address = 7;
   */
  address?: UserAddress;

  /**
   * True if all fields required to be allowed to order are set
   *
   * @generated from field: bool is_complete = 8;
   */
  isComplete = false;

  /**
   * True if the user is from a blocked country or postal code
   *
   * @generated from field: bool is_blocked = 9;
   */
  isBlocked = false;

  /**
   * Settings
   *
   * @generated from field: gportal.api.user.v1.UserSettings settings = 10;
   */
  settings?: UserSettings;

  /**
   * Avatar URL
   *
   * @generated from field: string avatar_url = 11;
   */
  avatarUrl = "";

  constructor(data?: PartialMessage<User>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.User";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "email_verified", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 5, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "address", kind: "message", T: UserAddress, opt: true },
    { no: 8, name: "is_complete", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "is_blocked", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "settings", kind: "message", T: UserSettings },
    { no: 11, name: "avatar_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): User {
    return new User().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): User {
    return new User().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): User {
    return new User().fromJsonString(jsonString, options);
  }

  static equals(a: User | PlainMessage<User> | undefined, b: User | PlainMessage<User> | undefined): boolean {
    return proto3.util.equals(User, a, b);
  }
}

/**
 * @generated from message gportal.api.user.v1.UserAddress
 */
export class UserAddress extends Message<UserAddress> {
  /**
   * @generated from field: optional string country = 1;
   */
  country?: string;

  /**
   * @generated from field: optional string locality = 2;
   */
  locality?: string;

  /**
   * @generated from field: optional string postal_code = 3;
   */
  postalCode?: string;

  /**
   * @generated from field: optional string street = 4;
   */
  street?: string;

  constructor(data?: PartialMessage<UserAddress>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.UserAddress";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "locality", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "street", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserAddress {
    return new UserAddress().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserAddress {
    return new UserAddress().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserAddress {
    return new UserAddress().fromJsonString(jsonString, options);
  }

  static equals(a: UserAddress | PlainMessage<UserAddress> | undefined, b: UserAddress | PlainMessage<UserAddress> | undefined): boolean {
    return proto3.util.equals(UserAddress, a, b);
  }
}

/**
 * @generated from message gportal.api.user.v1.UserSettings
 */
export class UserSettings extends Message<UserSettings> {
  /**
   * Locale Setting
   *
   * @generated from field: optional string locale = 1;
   */
  locale?: string;

  /**
   * Timezone setting
   *
   * @generated from field: optional string timezone = 2;
   */
  timezone?: string;

  /**
   * Primary region
   *
   * @generated from field: optional gportal.type.v1.Region preferred_region = 3;
   */
  preferredRegion?: Region;

  /**
   * Newsletter setting
   *
   * @generated from field: optional bool newsletter = 4;
   */
  newsletter?: boolean;

  constructor(data?: PartialMessage<UserSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.UserSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "locale", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "timezone", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "preferred_region", kind: "enum", T: proto3.getEnumType(Region), opt: true },
    { no: 4, name: "newsletter", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserSettings {
    return new UserSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserSettings {
    return new UserSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserSettings {
    return new UserSettings().fromJsonString(jsonString, options);
  }

  static equals(a: UserSettings | PlainMessage<UserSettings> | undefined, b: UserSettings | PlainMessage<UserSettings> | undefined): boolean {
    return proto3.util.equals(UserSettings, a, b);
  }
}

/**
 * @generated from message gportal.api.user.v1.UserProfile
 */
export class UserProfile extends Message<UserProfile> {
  /**
   * @generated from field: optional string username = 1;
   */
  username?: string;

  /**
   * @generated from field: optional string name = 2;
   */
  name?: string;

  /**
   * @generated from field: optional string email = 3;
   */
  email?: string;

  /**
   * @generated from field: optional bool email_verified = 4;
   */
  emailVerified?: boolean;

  /**
   * @generated from field: repeated gportal.api.user.v1.AuthenticationType authentication_types = 5;
   */
  authenticationTypes: AuthenticationType[] = [];

  /**
   * @generated from field: repeated gportal.api.user.v1.TwoFactorType two_factors = 6;
   */
  twoFactors: TwoFactorType[] = [];

  /**
   * @generated from field: optional google.protobuf.Timestamp password_set_at = 7;
   */
  passwordSetAt?: Timestamp;

  /**
   * @generated from field: optional string donation_link = 8;
   */
  donationLink?: string;

  /**
   * @generated from field: bool newsletter_subscription = 9;
   */
  newsletterSubscription = false;

  constructor(data?: PartialMessage<UserProfile>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.UserProfile";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "email_verified", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 5, name: "authentication_types", kind: "enum", T: proto3.getEnumType(AuthenticationType), repeated: true },
    { no: 6, name: "two_factors", kind: "enum", T: proto3.getEnumType(TwoFactorType), repeated: true },
    { no: 7, name: "password_set_at", kind: "message", T: Timestamp, opt: true },
    { no: 8, name: "donation_link", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "newsletter_subscription", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserProfile {
    return new UserProfile().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserProfile {
    return new UserProfile().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserProfile {
    return new UserProfile().fromJsonString(jsonString, options);
  }

  static equals(a: UserProfile | PlainMessage<UserProfile> | undefined, b: UserProfile | PlainMessage<UserProfile> | undefined): boolean {
    return proto3.util.equals(UserProfile, a, b);
  }
}

