import { defineNuxtPlugin } from '#app';
import type { VueMessageType } from 'vue-i18n';

export default defineNuxtPlugin((nuxt) => {
  const { $i18n } = useNuxtApp();

  const route = useRoute();
  if (route.query && route.query.disable_i18n === 'true') {
    $i18n.setPostTranslationHandler((key: string, value: VueMessageType) => {
      return value;
    });
  }
});
