import { GpRegion } from '~/plugins/grpc/universal/types';

export const USER_CURRENCY_STORE_KEY = 'userCurrency';

export const useCurrencyStore = defineStore('currency', () => {
  const state = reactive({
    currency: '' as string,
    preferUserDecision: false as boolean,
  });

  const setCurrency = (currency: string, preferUserDecision?: boolean) => {
    state.currency = currency;
    state.preferUserDecision = preferUserDecision;

    if (process.client && preferUserDecision) {
      localStorage.setItem(USER_CURRENCY_STORE_KEY, currency);
    }
  };

  return {
    ...toRefs(state),
    setCurrency,
  };
});

export const SELECTABLE_CUSTOMER_CURRENCIES = {
  [GpRegion.EUR]: ['EUR', 'GBP'],
  [GpRegion.INT]: ['USD', 'JPY'],
};
