import { GpRegion } from '~/plugins/grpc/universal/types';

export const LEGACY_REGION_EUR = 'EU';
export const LEGACY_REGION_INT = 'US';

export const convertGpRegionToLegacy = (
  websiteRegion: GpRegion,
): string | null => {
  switch (websiteRegion) {
    case GpRegion.EUR:
      return LEGACY_REGION_EUR;
    case GpRegion.INT:
      return LEGACY_REGION_INT;
    default:
      return null;
  }
};

export const LEGACY_REGION_USA = LEGACY_REGION_INT;

export const convertLegacyToGpRegion = (webinterfaceRegion: string) => {
  switch (webinterfaceRegion) {
    case LEGACY_REGION_EUR:
      return GpRegion.EUR;
    case LEGACY_REGION_INT:
      return GpRegion.INT;
  }

  return null;
};
