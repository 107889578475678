// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file gportal/api/user/v1/requests.proto (package gportal.api.user.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message gportal.api.user.v1.GetUserRequest
 */
export class GetUserRequest extends Message<GetUserRequest> {
  constructor(data?: PartialMessage<GetUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.GetUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUserRequest {
    return new GetUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUserRequest {
    return new GetUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUserRequest {
    return new GetUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetUserRequest | PlainMessage<GetUserRequest> | undefined, b: GetUserRequest | PlainMessage<GetUserRequest> | undefined): boolean {
    return proto3.util.equals(GetUserRequest, a, b);
  }
}

/**
 * @generated from message gportal.api.user.v1.GetCustomerRequest
 */
export class GetCustomerRequest extends Message<GetCustomerRequest> {
  constructor(data?: PartialMessage<GetCustomerRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.GetCustomerRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCustomerRequest {
    return new GetCustomerRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCustomerRequest {
    return new GetCustomerRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCustomerRequest {
    return new GetCustomerRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCustomerRequest | PlainMessage<GetCustomerRequest> | undefined, b: GetCustomerRequest | PlainMessage<GetCustomerRequest> | undefined): boolean {
    return proto3.util.equals(GetCustomerRequest, a, b);
  }
}

/**
 * @generated from message gportal.api.user.v1.CreateCustomerRequest
 */
export class CreateCustomerRequest extends Message<CreateCustomerRequest> {
  /**
   * @generated from field: optional string currency = 1;
   */
  currency?: string;

  /**
   * @generated from field: optional string reference_id = 2;
   */
  referenceId?: string;

  constructor(data?: PartialMessage<CreateCustomerRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.CreateCustomerRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "reference_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateCustomerRequest {
    return new CreateCustomerRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateCustomerRequest {
    return new CreateCustomerRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateCustomerRequest {
    return new CreateCustomerRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateCustomerRequest | PlainMessage<CreateCustomerRequest> | undefined, b: CreateCustomerRequest | PlainMessage<CreateCustomerRequest> | undefined): boolean {
    return proto3.util.equals(CreateCustomerRequest, a, b);
  }
}

/**
 * @generated from message gportal.api.user.v1.RegisterUserRequest
 */
export class RegisterUserRequest extends Message<RegisterUserRequest> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * @generated from field: string password = 2;
   */
  password = "";

  /**
   * @generated from field: string username = 4;
   */
  username = "";

  /**
   * @generated from field: string firstname = 5;
   */
  firstname = "";

  /**
   * @generated from field: string lastname = 6;
   */
  lastname = "";

  /**
   * @generated from field: string country = 7;
   */
  country = "";

  /**
   * @generated from field: string postal_code = 8;
   */
  postalCode = "";

  /**
   * @generated from field: string locality = 9;
   */
  locality = "";

  /**
   * @generated from field: string street = 10;
   */
  street = "";

  /**
   * @generated from field: string locale = 11;
   */
  locale = "";

  /**
   * @generated from field: string timezone = 12;
   */
  timezone = "";

  /**
   * @generated from field: bool newsletter = 13;
   */
  newsletter = false;

  /**
   * @generated from field: bool terms = 14;
   */
  terms = false;

  /**
   * @generated from field: string recaptcha_token = 15;
   */
  recaptchaToken = "";

  constructor(data?: PartialMessage<RegisterUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.RegisterUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "firstname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "lastname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "locality", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "street", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "locale", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "newsletter", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "terms", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "recaptcha_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RegisterUserRequest {
    return new RegisterUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RegisterUserRequest {
    return new RegisterUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RegisterUserRequest {
    return new RegisterUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RegisterUserRequest | PlainMessage<RegisterUserRequest> | undefined, b: RegisterUserRequest | PlainMessage<RegisterUserRequest> | undefined): boolean {
    return proto3.util.equals(RegisterUserRequest, a, b);
  }
}

/**
 * @generated from message gportal.api.user.v1.RegisterUserRequirementsRequest
 */
export class RegisterUserRequirementsRequest extends Message<RegisterUserRequirementsRequest> {
  /**
   * @generated from field: optional string locale = 1;
   */
  locale?: string;

  constructor(data?: PartialMessage<RegisterUserRequirementsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.RegisterUserRequirementsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "locale", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RegisterUserRequirementsRequest {
    return new RegisterUserRequirementsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RegisterUserRequirementsRequest {
    return new RegisterUserRequirementsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RegisterUserRequirementsRequest {
    return new RegisterUserRequirementsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RegisterUserRequirementsRequest | PlainMessage<RegisterUserRequirementsRequest> | undefined, b: RegisterUserRequirementsRequest | PlainMessage<RegisterUserRequirementsRequest> | undefined): boolean {
    return proto3.util.equals(RegisterUserRequirementsRequest, a, b);
  }
}

/**
 * @generated from message gportal.api.user.v1.GetProfileRequest
 */
export class GetProfileRequest extends Message<GetProfileRequest> {
  constructor(data?: PartialMessage<GetProfileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.GetProfileRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProfileRequest {
    return new GetProfileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProfileRequest {
    return new GetProfileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProfileRequest {
    return new GetProfileRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetProfileRequest | PlainMessage<GetProfileRequest> | undefined, b: GetProfileRequest | PlainMessage<GetProfileRequest> | undefined): boolean {
    return proto3.util.equals(GetProfileRequest, a, b);
  }
}

/**
 * @generated from message gportal.api.user.v1.VerifyEmailRequest
 */
export class VerifyEmailRequest extends Message<VerifyEmailRequest> {
  /**
   * @generated from field: string verification_code = 1;
   */
  verificationCode = "";

  constructor(data?: PartialMessage<VerifyEmailRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.VerifyEmailRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "verification_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VerifyEmailRequest {
    return new VerifyEmailRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VerifyEmailRequest {
    return new VerifyEmailRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VerifyEmailRequest {
    return new VerifyEmailRequest().fromJsonString(jsonString, options);
  }

  static equals(a: VerifyEmailRequest | PlainMessage<VerifyEmailRequest> | undefined, b: VerifyEmailRequest | PlainMessage<VerifyEmailRequest> | undefined): boolean {
    return proto3.util.equals(VerifyEmailRequest, a, b);
  }
}

/**
 * @generated from message gportal.api.user.v1.ResendVerificationEmailRequest
 */
export class ResendVerificationEmailRequest extends Message<ResendVerificationEmailRequest> {
  constructor(data?: PartialMessage<ResendVerificationEmailRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.ResendVerificationEmailRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResendVerificationEmailRequest {
    return new ResendVerificationEmailRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResendVerificationEmailRequest {
    return new ResendVerificationEmailRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResendVerificationEmailRequest {
    return new ResendVerificationEmailRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ResendVerificationEmailRequest | PlainMessage<ResendVerificationEmailRequest> | undefined, b: ResendVerificationEmailRequest | PlainMessage<ResendVerificationEmailRequest> | undefined): boolean {
    return proto3.util.equals(ResendVerificationEmailRequest, a, b);
  }
}

/**
 * @generated from message gportal.api.user.v1.ChangePreferredRegionRequest
 */
export class ChangePreferredRegionRequest extends Message<ChangePreferredRegionRequest> {
  /**
   * @generated from field: string new_region = 1;
   */
  newRegion = "";

  constructor(data?: PartialMessage<ChangePreferredRegionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.ChangePreferredRegionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "new_region", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChangePreferredRegionRequest {
    return new ChangePreferredRegionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChangePreferredRegionRequest {
    return new ChangePreferredRegionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChangePreferredRegionRequest {
    return new ChangePreferredRegionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ChangePreferredRegionRequest | PlainMessage<ChangePreferredRegionRequest> | undefined, b: ChangePreferredRegionRequest | PlainMessage<ChangePreferredRegionRequest> | undefined): boolean {
    return proto3.util.equals(ChangePreferredRegionRequest, a, b);
  }
}

/**
 * @generated from message gportal.api.user.v1.ChangeLocaleRequest
 */
export class ChangeLocaleRequest extends Message<ChangeLocaleRequest> {
  /**
   * @generated from field: string new_locale = 1;
   */
  newLocale = "";

  constructor(data?: PartialMessage<ChangeLocaleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.ChangeLocaleRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "new_locale", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChangeLocaleRequest {
    return new ChangeLocaleRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChangeLocaleRequest {
    return new ChangeLocaleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChangeLocaleRequest {
    return new ChangeLocaleRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ChangeLocaleRequest | PlainMessage<ChangeLocaleRequest> | undefined, b: ChangeLocaleRequest | PlainMessage<ChangeLocaleRequest> | undefined): boolean {
    return proto3.util.equals(ChangeLocaleRequest, a, b);
  }
}

/**
 * @generated from message gportal.api.user.v1.ChangeCurrencyRequest
 */
export class ChangeCurrencyRequest extends Message<ChangeCurrencyRequest> {
  /**
   * @generated from field: string new_currency = 1;
   */
  newCurrency = "";

  constructor(data?: PartialMessage<ChangeCurrencyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.ChangeCurrencyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "new_currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChangeCurrencyRequest {
    return new ChangeCurrencyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChangeCurrencyRequest {
    return new ChangeCurrencyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChangeCurrencyRequest {
    return new ChangeCurrencyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ChangeCurrencyRequest | PlainMessage<ChangeCurrencyRequest> | undefined, b: ChangeCurrencyRequest | PlainMessage<ChangeCurrencyRequest> | undefined): boolean {
    return proto3.util.equals(ChangeCurrencyRequest, a, b);
  }
}

/**
 * @generated from message gportal.api.user.v1.ChangeTimezoneRequest
 */
export class ChangeTimezoneRequest extends Message<ChangeTimezoneRequest> {
  /**
   * @generated from field: string new_timezone = 1;
   */
  newTimezone = "";

  constructor(data?: PartialMessage<ChangeTimezoneRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.ChangeTimezoneRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "new_timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChangeTimezoneRequest {
    return new ChangeTimezoneRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChangeTimezoneRequest {
    return new ChangeTimezoneRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChangeTimezoneRequest {
    return new ChangeTimezoneRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ChangeTimezoneRequest | PlainMessage<ChangeTimezoneRequest> | undefined, b: ChangeTimezoneRequest | PlainMessage<ChangeTimezoneRequest> | undefined): boolean {
    return proto3.util.equals(ChangeTimezoneRequest, a, b);
  }
}

/**
 * @generated from message gportal.api.user.v1.NewsletterOptInRequest
 */
export class NewsletterOptInRequest extends Message<NewsletterOptInRequest> {
  /**
   * @generated from field: optional string email = 1;
   */
  email?: string;

  /**
   * @generated from field: optional string locale = 2;
   */
  locale?: string;

  /**
   * @generated from field: optional string language = 3;
   */
  language?: string;

  constructor(data?: PartialMessage<NewsletterOptInRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.NewsletterOptInRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "locale", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "language", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NewsletterOptInRequest {
    return new NewsletterOptInRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NewsletterOptInRequest {
    return new NewsletterOptInRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NewsletterOptInRequest {
    return new NewsletterOptInRequest().fromJsonString(jsonString, options);
  }

  static equals(a: NewsletterOptInRequest | PlainMessage<NewsletterOptInRequest> | undefined, b: NewsletterOptInRequest | PlainMessage<NewsletterOptInRequest> | undefined): boolean {
    return proto3.util.equals(NewsletterOptInRequest, a, b);
  }
}

/**
 * @generated from message gportal.api.user.v1.NewsletterOptOutRequest
 */
export class NewsletterOptOutRequest extends Message<NewsletterOptOutRequest> {
  constructor(data?: PartialMessage<NewsletterOptOutRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.NewsletterOptOutRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NewsletterOptOutRequest {
    return new NewsletterOptOutRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NewsletterOptOutRequest {
    return new NewsletterOptOutRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NewsletterOptOutRequest {
    return new NewsletterOptOutRequest().fromJsonString(jsonString, options);
  }

  static equals(a: NewsletterOptOutRequest | PlainMessage<NewsletterOptOutRequest> | undefined, b: NewsletterOptOutRequest | PlainMessage<NewsletterOptOutRequest> | undefined): boolean {
    return proto3.util.equals(NewsletterOptOutRequest, a, b);
  }
}

