// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file gportal/type/v1/region.proto (package gportal.type.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum gportal.type.v1.Region
 */
export enum Region {
  /**
   * @generated from enum value: REGION_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: REGION_EUR = 1;
   */
  EUR = 1,

  /**
   * @generated from enum value: REGION_INT = 2;
   */
  INT = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Region)
proto3.util.setEnumType(Region, "gportal.type.v1.Region", [
  { no: 0, name: "REGION_UNSPECIFIED" },
  { no: 1, name: "REGION_EUR" },
  { no: 2, name: "REGION_INT" },
]);

