import type { LatencyLocation } from '~/store/types';

export default defineNuxtPlugin(async () => {
  const interval = ref<number>();
  const locationStore = useLocationStore();
  const { locations } = useAppConfig();

  const runMeasurements = () => {
    locationStore.runLatencyMeasurements(locations as LatencyLocation[]);
  };

  // Since latency is not a critical feature, we can wait for the app to be ready before starting the measurements
  onNuxtReady(() => {
    runMeasurements();
    interval.value = window.setInterval(
      () => {
        runMeasurements();
      },
      1000 * 60 * 2,
    );
  });

  console.log('[Latency]: Plugin loaded.');
});
