import { default as _91slug_9335l6bYnVMHMeta } from "/opt/app-root/src/website/pages/blog/[slug].vue?macro=true";
import { default as indexh3ObvJsjoxMeta } from "/opt/app-root/src/website/pages/index.vue?macro=true";
import { default as _91slug_9316BshLr53xMeta } from "/opt/app-root/src/website/pages/landingpage/gameserver/[slug].vue?macro=true";
import { default as _91slug_93l1Pnc2nHUOMeta } from "/opt/app-root/src/website/pages/landingpage/voiceserver/[slug].vue?macro=true";
import { default as _91slug_93fOcbYyUgHPMeta } from "/opt/app-root/src/website/pages/news/[slug].vue?macro=true";
import { default as indexdoIqKzNxtGMeta } from "/opt/app-root/src/website/pages/news/index.vue?macro=true";
import { default as callbackE8wdAJCyPeMeta } from "/opt/app-root/src/website/pages/nuxt/callback.vue?macro=true";
import { default as index5D0TyRujqLMeta } from "/opt/app-root/src/website/pages/partner/index.vue?macro=true";
import { default as indexKd2cv8vBo6Meta } from "/opt/app-root/src/website/pages/profile/index.vue?macro=true";
import { default as indexc0AB1oOrtnMeta } from "/opt/app-root/src/website/pages/register/index.vue?macro=true";
import { default as indexvEaUqa6s6cMeta } from "/opt/app-root/src/website/pages/sustainability/index.vue?macro=true";
export default [
  {
    name: _91slug_9335l6bYnVMHMeta?.name ?? "blog-slug___de",
    path: _91slug_9335l6bYnVMHMeta?.path ?? "/de/blog/:slug()",
    meta: _91slug_9335l6bYnVMHMeta || {},
    alias: _91slug_9335l6bYnVMHMeta?.alias || [],
    redirect: _91slug_9335l6bYnVMHMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9335l6bYnVMHMeta?.name ?? "blog-slug___en",
    path: _91slug_9335l6bYnVMHMeta?.path ?? "/en/blog/:slug()",
    meta: _91slug_9335l6bYnVMHMeta || {},
    alias: _91slug_9335l6bYnVMHMeta?.alias || [],
    redirect: _91slug_9335l6bYnVMHMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9335l6bYnVMHMeta?.name ?? "blog-slug___fr",
    path: _91slug_9335l6bYnVMHMeta?.path ?? "/fr/blog/:slug()",
    meta: _91slug_9335l6bYnVMHMeta || {},
    alias: _91slug_9335l6bYnVMHMeta?.alias || [],
    redirect: _91slug_9335l6bYnVMHMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9335l6bYnVMHMeta?.name ?? "blog-slug___it",
    path: _91slug_9335l6bYnVMHMeta?.path ?? "/it/blog/:slug()",
    meta: _91slug_9335l6bYnVMHMeta || {},
    alias: _91slug_9335l6bYnVMHMeta?.alias || [],
    redirect: _91slug_9335l6bYnVMHMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9335l6bYnVMHMeta?.name ?? "blog-slug___pl",
    path: _91slug_9335l6bYnVMHMeta?.path ?? "/pl/blog/:slug()",
    meta: _91slug_9335l6bYnVMHMeta || {},
    alias: _91slug_9335l6bYnVMHMeta?.alias || [],
    redirect: _91slug_9335l6bYnVMHMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9335l6bYnVMHMeta?.name ?? "blog-slug___es",
    path: _91slug_9335l6bYnVMHMeta?.path ?? "/es/blog/:slug()",
    meta: _91slug_9335l6bYnVMHMeta || {},
    alias: _91slug_9335l6bYnVMHMeta?.alias || [],
    redirect: _91slug_9335l6bYnVMHMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9335l6bYnVMHMeta?.name ?? "blog-slug___pt",
    path: _91slug_9335l6bYnVMHMeta?.path ?? "/pt/blog/:slug()",
    meta: _91slug_9335l6bYnVMHMeta || {},
    alias: _91slug_9335l6bYnVMHMeta?.alias || [],
    redirect: _91slug_9335l6bYnVMHMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9335l6bYnVMHMeta?.name ?? "blog-slug___nl",
    path: _91slug_9335l6bYnVMHMeta?.path ?? "/nl/blog/:slug()",
    meta: _91slug_9335l6bYnVMHMeta || {},
    alias: _91slug_9335l6bYnVMHMeta?.alias || [],
    redirect: _91slug_9335l6bYnVMHMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9335l6bYnVMHMeta?.name ?? "blog-slug___ja",
    path: _91slug_9335l6bYnVMHMeta?.path ?? "/ja/blog/:slug()",
    meta: _91slug_9335l6bYnVMHMeta || {},
    alias: _91slug_9335l6bYnVMHMeta?.alias || [],
    redirect: _91slug_9335l6bYnVMHMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9335l6bYnVMHMeta?.name ?? "blog-slug___zh",
    path: _91slug_9335l6bYnVMHMeta?.path ?? "/zh/blog/:slug()",
    meta: _91slug_9335l6bYnVMHMeta || {},
    alias: _91slug_9335l6bYnVMHMeta?.alias || [],
    redirect: _91slug_9335l6bYnVMHMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexh3ObvJsjoxMeta?.name ?? "index___de",
    path: indexh3ObvJsjoxMeta?.path ?? "/de",
    meta: indexh3ObvJsjoxMeta || {},
    alias: indexh3ObvJsjoxMeta?.alias || [],
    redirect: indexh3ObvJsjoxMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexh3ObvJsjoxMeta?.name ?? "index___en",
    path: indexh3ObvJsjoxMeta?.path ?? "/en",
    meta: indexh3ObvJsjoxMeta || {},
    alias: indexh3ObvJsjoxMeta?.alias || [],
    redirect: indexh3ObvJsjoxMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexh3ObvJsjoxMeta?.name ?? "index___fr",
    path: indexh3ObvJsjoxMeta?.path ?? "/fr",
    meta: indexh3ObvJsjoxMeta || {},
    alias: indexh3ObvJsjoxMeta?.alias || [],
    redirect: indexh3ObvJsjoxMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexh3ObvJsjoxMeta?.name ?? "index___it",
    path: indexh3ObvJsjoxMeta?.path ?? "/it",
    meta: indexh3ObvJsjoxMeta || {},
    alias: indexh3ObvJsjoxMeta?.alias || [],
    redirect: indexh3ObvJsjoxMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexh3ObvJsjoxMeta?.name ?? "index___pl",
    path: indexh3ObvJsjoxMeta?.path ?? "/pl",
    meta: indexh3ObvJsjoxMeta || {},
    alias: indexh3ObvJsjoxMeta?.alias || [],
    redirect: indexh3ObvJsjoxMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexh3ObvJsjoxMeta?.name ?? "index___es",
    path: indexh3ObvJsjoxMeta?.path ?? "/es",
    meta: indexh3ObvJsjoxMeta || {},
    alias: indexh3ObvJsjoxMeta?.alias || [],
    redirect: indexh3ObvJsjoxMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexh3ObvJsjoxMeta?.name ?? "index___pt",
    path: indexh3ObvJsjoxMeta?.path ?? "/pt",
    meta: indexh3ObvJsjoxMeta || {},
    alias: indexh3ObvJsjoxMeta?.alias || [],
    redirect: indexh3ObvJsjoxMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexh3ObvJsjoxMeta?.name ?? "index___nl",
    path: indexh3ObvJsjoxMeta?.path ?? "/nl",
    meta: indexh3ObvJsjoxMeta || {},
    alias: indexh3ObvJsjoxMeta?.alias || [],
    redirect: indexh3ObvJsjoxMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexh3ObvJsjoxMeta?.name ?? "index___ja",
    path: indexh3ObvJsjoxMeta?.path ?? "/ja",
    meta: indexh3ObvJsjoxMeta || {},
    alias: indexh3ObvJsjoxMeta?.alias || [],
    redirect: indexh3ObvJsjoxMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexh3ObvJsjoxMeta?.name ?? "index___zh",
    path: indexh3ObvJsjoxMeta?.path ?? "/zh",
    meta: indexh3ObvJsjoxMeta || {},
    alias: indexh3ObvJsjoxMeta?.alias || [],
    redirect: indexh3ObvJsjoxMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_9316BshLr53xMeta?.name ?? "landingpage-gameserver-slug___de",
    path: _91slug_9316BshLr53xMeta?.path ?? "/de/gameserver/:slug()",
    meta: _91slug_9316BshLr53xMeta || {},
    alias: _91slug_9316BshLr53xMeta?.alias || [],
    redirect: _91slug_9316BshLr53xMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/landingpage/gameserver/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9316BshLr53xMeta?.name ?? "landingpage-gameserver-slug___en",
    path: _91slug_9316BshLr53xMeta?.path ?? "/en/gameserver/:slug()",
    meta: _91slug_9316BshLr53xMeta || {},
    alias: _91slug_9316BshLr53xMeta?.alias || [],
    redirect: _91slug_9316BshLr53xMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/landingpage/gameserver/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9316BshLr53xMeta?.name ?? "landingpage-gameserver-slug___fr",
    path: _91slug_9316BshLr53xMeta?.path ?? "/fr/serveur-de-jeu/:slug()",
    meta: _91slug_9316BshLr53xMeta || {},
    alias: _91slug_9316BshLr53xMeta?.alias || [],
    redirect: _91slug_9316BshLr53xMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/landingpage/gameserver/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9316BshLr53xMeta?.name ?? "landingpage-gameserver-slug___it",
    path: _91slug_9316BshLr53xMeta?.path ?? "/it/server-di-gioco/:slug()",
    meta: _91slug_9316BshLr53xMeta || {},
    alias: _91slug_9316BshLr53xMeta?.alias || [],
    redirect: _91slug_9316BshLr53xMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/landingpage/gameserver/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9316BshLr53xMeta?.name ?? "landingpage-gameserver-slug___pl",
    path: _91slug_9316BshLr53xMeta?.path ?? "/pl/serwery-gier/:slug()",
    meta: _91slug_9316BshLr53xMeta || {},
    alias: _91slug_9316BshLr53xMeta?.alias || [],
    redirect: _91slug_9316BshLr53xMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/landingpage/gameserver/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9316BshLr53xMeta?.name ?? "landingpage-gameserver-slug___es",
    path: _91slug_9316BshLr53xMeta?.path ?? "/es/gameserver/:slug()",
    meta: _91slug_9316BshLr53xMeta || {},
    alias: _91slug_9316BshLr53xMeta?.alias || [],
    redirect: _91slug_9316BshLr53xMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/landingpage/gameserver/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9316BshLr53xMeta?.name ?? "landingpage-gameserver-slug___pt",
    path: _91slug_9316BshLr53xMeta?.path ?? "/pt/servidor-de-jogos/:slug()",
    meta: _91slug_9316BshLr53xMeta || {},
    alias: _91slug_9316BshLr53xMeta?.alias || [],
    redirect: _91slug_9316BshLr53xMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/landingpage/gameserver/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9316BshLr53xMeta?.name ?? "landingpage-gameserver-slug___nl",
    path: _91slug_9316BshLr53xMeta?.path ?? "/nl/gameserver/:slug()",
    meta: _91slug_9316BshLr53xMeta || {},
    alias: _91slug_9316BshLr53xMeta?.alias || [],
    redirect: _91slug_9316BshLr53xMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/landingpage/gameserver/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9316BshLr53xMeta?.name ?? "landingpage-gameserver-slug___ja",
    path: _91slug_9316BshLr53xMeta?.path ?? "/ja/gameserver/:slug()",
    meta: _91slug_9316BshLr53xMeta || {},
    alias: _91slug_9316BshLr53xMeta?.alias || [],
    redirect: _91slug_9316BshLr53xMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/landingpage/gameserver/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9316BshLr53xMeta?.name ?? "landingpage-gameserver-slug___zh",
    path: _91slug_9316BshLr53xMeta?.path ?? "/zh/gameserver/:slug()",
    meta: _91slug_9316BshLr53xMeta || {},
    alias: _91slug_9316BshLr53xMeta?.alias || [],
    redirect: _91slug_9316BshLr53xMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/landingpage/gameserver/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93l1Pnc2nHUOMeta?.name ?? "landingpage-voiceserver-slug___de",
    path: _91slug_93l1Pnc2nHUOMeta?.path ?? "/de/voiceserver/:slug()",
    meta: _91slug_93l1Pnc2nHUOMeta || {},
    alias: _91slug_93l1Pnc2nHUOMeta?.alias || [],
    redirect: _91slug_93l1Pnc2nHUOMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/landingpage/voiceserver/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93l1Pnc2nHUOMeta?.name ?? "landingpage-voiceserver-slug___en",
    path: _91slug_93l1Pnc2nHUOMeta?.path ?? "/en/voiceserver/:slug()",
    meta: _91slug_93l1Pnc2nHUOMeta || {},
    alias: _91slug_93l1Pnc2nHUOMeta?.alias || [],
    redirect: _91slug_93l1Pnc2nHUOMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/landingpage/voiceserver/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93l1Pnc2nHUOMeta?.name ?? "landingpage-voiceserver-slug___fr",
    path: _91slug_93l1Pnc2nHUOMeta?.path ?? "/fr/serveur-de-jeu/:slug()",
    meta: _91slug_93l1Pnc2nHUOMeta || {},
    alias: _91slug_93l1Pnc2nHUOMeta?.alias || [],
    redirect: _91slug_93l1Pnc2nHUOMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/landingpage/voiceserver/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93l1Pnc2nHUOMeta?.name ?? "landingpage-voiceserver-slug___it",
    path: _91slug_93l1Pnc2nHUOMeta?.path ?? "/it/server-di-voce/:slug()",
    meta: _91slug_93l1Pnc2nHUOMeta || {},
    alias: _91slug_93l1Pnc2nHUOMeta?.alias || [],
    redirect: _91slug_93l1Pnc2nHUOMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/landingpage/voiceserver/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93l1Pnc2nHUOMeta?.name ?? "landingpage-voiceserver-slug___pl",
    path: _91slug_93l1Pnc2nHUOMeta?.path ?? "/pl/serwery-gier/:slug()",
    meta: _91slug_93l1Pnc2nHUOMeta || {},
    alias: _91slug_93l1Pnc2nHUOMeta?.alias || [],
    redirect: _91slug_93l1Pnc2nHUOMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/landingpage/voiceserver/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93l1Pnc2nHUOMeta?.name ?? "landingpage-voiceserver-slug___es",
    path: _91slug_93l1Pnc2nHUOMeta?.path ?? "/es/voiceserver/:slug()",
    meta: _91slug_93l1Pnc2nHUOMeta || {},
    alias: _91slug_93l1Pnc2nHUOMeta?.alias || [],
    redirect: _91slug_93l1Pnc2nHUOMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/landingpage/voiceserver/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93l1Pnc2nHUOMeta?.name ?? "landingpage-voiceserver-slug___pt",
    path: _91slug_93l1Pnc2nHUOMeta?.path ?? "/pt/voiceserver/:slug()",
    meta: _91slug_93l1Pnc2nHUOMeta || {},
    alias: _91slug_93l1Pnc2nHUOMeta?.alias || [],
    redirect: _91slug_93l1Pnc2nHUOMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/landingpage/voiceserver/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93l1Pnc2nHUOMeta?.name ?? "landingpage-voiceserver-slug___nl",
    path: _91slug_93l1Pnc2nHUOMeta?.path ?? "/nl/voiceserver/:slug()",
    meta: _91slug_93l1Pnc2nHUOMeta || {},
    alias: _91slug_93l1Pnc2nHUOMeta?.alias || [],
    redirect: _91slug_93l1Pnc2nHUOMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/landingpage/voiceserver/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93l1Pnc2nHUOMeta?.name ?? "landingpage-voiceserver-slug___ja",
    path: _91slug_93l1Pnc2nHUOMeta?.path ?? "/ja/voiceserver/:slug()",
    meta: _91slug_93l1Pnc2nHUOMeta || {},
    alias: _91slug_93l1Pnc2nHUOMeta?.alias || [],
    redirect: _91slug_93l1Pnc2nHUOMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/landingpage/voiceserver/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93l1Pnc2nHUOMeta?.name ?? "landingpage-voiceserver-slug___zh",
    path: _91slug_93l1Pnc2nHUOMeta?.path ?? "/zh/voiceserver/:slug()",
    meta: _91slug_93l1Pnc2nHUOMeta || {},
    alias: _91slug_93l1Pnc2nHUOMeta?.alias || [],
    redirect: _91slug_93l1Pnc2nHUOMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/landingpage/voiceserver/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fOcbYyUgHPMeta?.name ?? "news-slug___de",
    path: _91slug_93fOcbYyUgHPMeta?.path ?? "/de/news/:slug()",
    meta: _91slug_93fOcbYyUgHPMeta || {},
    alias: _91slug_93fOcbYyUgHPMeta?.alias || [],
    redirect: _91slug_93fOcbYyUgHPMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fOcbYyUgHPMeta?.name ?? "news-slug___en",
    path: _91slug_93fOcbYyUgHPMeta?.path ?? "/en/news/:slug()",
    meta: _91slug_93fOcbYyUgHPMeta || {},
    alias: _91slug_93fOcbYyUgHPMeta?.alias || [],
    redirect: _91slug_93fOcbYyUgHPMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fOcbYyUgHPMeta?.name ?? "news-slug___fr",
    path: _91slug_93fOcbYyUgHPMeta?.path ?? "/fr/news/:slug()",
    meta: _91slug_93fOcbYyUgHPMeta || {},
    alias: _91slug_93fOcbYyUgHPMeta?.alias || [],
    redirect: _91slug_93fOcbYyUgHPMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fOcbYyUgHPMeta?.name ?? "news-slug___it",
    path: _91slug_93fOcbYyUgHPMeta?.path ?? "/it/news/:slug()",
    meta: _91slug_93fOcbYyUgHPMeta || {},
    alias: _91slug_93fOcbYyUgHPMeta?.alias || [],
    redirect: _91slug_93fOcbYyUgHPMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fOcbYyUgHPMeta?.name ?? "news-slug___pl",
    path: _91slug_93fOcbYyUgHPMeta?.path ?? "/pl/news/:slug()",
    meta: _91slug_93fOcbYyUgHPMeta || {},
    alias: _91slug_93fOcbYyUgHPMeta?.alias || [],
    redirect: _91slug_93fOcbYyUgHPMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fOcbYyUgHPMeta?.name ?? "news-slug___es",
    path: _91slug_93fOcbYyUgHPMeta?.path ?? "/es/news/:slug()",
    meta: _91slug_93fOcbYyUgHPMeta || {},
    alias: _91slug_93fOcbYyUgHPMeta?.alias || [],
    redirect: _91slug_93fOcbYyUgHPMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fOcbYyUgHPMeta?.name ?? "news-slug___pt",
    path: _91slug_93fOcbYyUgHPMeta?.path ?? "/pt/news/:slug()",
    meta: _91slug_93fOcbYyUgHPMeta || {},
    alias: _91slug_93fOcbYyUgHPMeta?.alias || [],
    redirect: _91slug_93fOcbYyUgHPMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fOcbYyUgHPMeta?.name ?? "news-slug___nl",
    path: _91slug_93fOcbYyUgHPMeta?.path ?? "/nl/news/:slug()",
    meta: _91slug_93fOcbYyUgHPMeta || {},
    alias: _91slug_93fOcbYyUgHPMeta?.alias || [],
    redirect: _91slug_93fOcbYyUgHPMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fOcbYyUgHPMeta?.name ?? "news-slug___ja",
    path: _91slug_93fOcbYyUgHPMeta?.path ?? "/ja/news/:slug()",
    meta: _91slug_93fOcbYyUgHPMeta || {},
    alias: _91slug_93fOcbYyUgHPMeta?.alias || [],
    redirect: _91slug_93fOcbYyUgHPMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fOcbYyUgHPMeta?.name ?? "news-slug___zh",
    path: _91slug_93fOcbYyUgHPMeta?.path ?? "/zh/news/:slug()",
    meta: _91slug_93fOcbYyUgHPMeta || {},
    alias: _91slug_93fOcbYyUgHPMeta?.alias || [],
    redirect: _91slug_93fOcbYyUgHPMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexdoIqKzNxtGMeta?.name ?? "news___de",
    path: indexdoIqKzNxtGMeta?.path ?? "/de/news",
    meta: indexdoIqKzNxtGMeta || {},
    alias: indexdoIqKzNxtGMeta?.alias || [],
    redirect: indexdoIqKzNxtGMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexdoIqKzNxtGMeta?.name ?? "news___en",
    path: indexdoIqKzNxtGMeta?.path ?? "/en/news",
    meta: indexdoIqKzNxtGMeta || {},
    alias: indexdoIqKzNxtGMeta?.alias || [],
    redirect: indexdoIqKzNxtGMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexdoIqKzNxtGMeta?.name ?? "news___fr",
    path: indexdoIqKzNxtGMeta?.path ?? "/fr/news",
    meta: indexdoIqKzNxtGMeta || {},
    alias: indexdoIqKzNxtGMeta?.alias || [],
    redirect: indexdoIqKzNxtGMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexdoIqKzNxtGMeta?.name ?? "news___it",
    path: indexdoIqKzNxtGMeta?.path ?? "/it/news",
    meta: indexdoIqKzNxtGMeta || {},
    alias: indexdoIqKzNxtGMeta?.alias || [],
    redirect: indexdoIqKzNxtGMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexdoIqKzNxtGMeta?.name ?? "news___pl",
    path: indexdoIqKzNxtGMeta?.path ?? "/pl/news",
    meta: indexdoIqKzNxtGMeta || {},
    alias: indexdoIqKzNxtGMeta?.alias || [],
    redirect: indexdoIqKzNxtGMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexdoIqKzNxtGMeta?.name ?? "news___es",
    path: indexdoIqKzNxtGMeta?.path ?? "/es/news",
    meta: indexdoIqKzNxtGMeta || {},
    alias: indexdoIqKzNxtGMeta?.alias || [],
    redirect: indexdoIqKzNxtGMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexdoIqKzNxtGMeta?.name ?? "news___pt",
    path: indexdoIqKzNxtGMeta?.path ?? "/pt/news",
    meta: indexdoIqKzNxtGMeta || {},
    alias: indexdoIqKzNxtGMeta?.alias || [],
    redirect: indexdoIqKzNxtGMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexdoIqKzNxtGMeta?.name ?? "news___nl",
    path: indexdoIqKzNxtGMeta?.path ?? "/nl/news",
    meta: indexdoIqKzNxtGMeta || {},
    alias: indexdoIqKzNxtGMeta?.alias || [],
    redirect: indexdoIqKzNxtGMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexdoIqKzNxtGMeta?.name ?? "news___ja",
    path: indexdoIqKzNxtGMeta?.path ?? "/ja/news",
    meta: indexdoIqKzNxtGMeta || {},
    alias: indexdoIqKzNxtGMeta?.alias || [],
    redirect: indexdoIqKzNxtGMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexdoIqKzNxtGMeta?.name ?? "news___zh",
    path: indexdoIqKzNxtGMeta?.path ?? "/zh/news",
    meta: indexdoIqKzNxtGMeta || {},
    alias: indexdoIqKzNxtGMeta?.alias || [],
    redirect: indexdoIqKzNxtGMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: callbackE8wdAJCyPeMeta?.name ?? "nuxt-callback",
    path: callbackE8wdAJCyPeMeta?.path ?? "/nuxt/callback",
    meta: callbackE8wdAJCyPeMeta || {},
    alias: callbackE8wdAJCyPeMeta?.alias || [],
    redirect: callbackE8wdAJCyPeMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/nuxt/callback.vue").then(m => m.default || m)
  },
  {
    name: index5D0TyRujqLMeta?.name ?? "partner___de",
    path: index5D0TyRujqLMeta?.path ?? "/de/partner",
    meta: index5D0TyRujqLMeta || {},
    alias: index5D0TyRujqLMeta?.alias || [],
    redirect: index5D0TyRujqLMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: index5D0TyRujqLMeta?.name ?? "partner___en",
    path: index5D0TyRujqLMeta?.path ?? "/en/partner",
    meta: index5D0TyRujqLMeta || {},
    alias: index5D0TyRujqLMeta?.alias || [],
    redirect: index5D0TyRujqLMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: index5D0TyRujqLMeta?.name ?? "partner___fr",
    path: index5D0TyRujqLMeta?.path ?? "/fr/partner",
    meta: index5D0TyRujqLMeta || {},
    alias: index5D0TyRujqLMeta?.alias || [],
    redirect: index5D0TyRujqLMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: index5D0TyRujqLMeta?.name ?? "partner___it",
    path: index5D0TyRujqLMeta?.path ?? "/it/partner",
    meta: index5D0TyRujqLMeta || {},
    alias: index5D0TyRujqLMeta?.alias || [],
    redirect: index5D0TyRujqLMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: index5D0TyRujqLMeta?.name ?? "partner___pl",
    path: index5D0TyRujqLMeta?.path ?? "/pl/partner",
    meta: index5D0TyRujqLMeta || {},
    alias: index5D0TyRujqLMeta?.alias || [],
    redirect: index5D0TyRujqLMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: index5D0TyRujqLMeta?.name ?? "partner___es",
    path: index5D0TyRujqLMeta?.path ?? "/es/partner",
    meta: index5D0TyRujqLMeta || {},
    alias: index5D0TyRujqLMeta?.alias || [],
    redirect: index5D0TyRujqLMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: index5D0TyRujqLMeta?.name ?? "partner___pt",
    path: index5D0TyRujqLMeta?.path ?? "/pt/partner",
    meta: index5D0TyRujqLMeta || {},
    alias: index5D0TyRujqLMeta?.alias || [],
    redirect: index5D0TyRujqLMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: index5D0TyRujqLMeta?.name ?? "partner___nl",
    path: index5D0TyRujqLMeta?.path ?? "/nl/partner",
    meta: index5D0TyRujqLMeta || {},
    alias: index5D0TyRujqLMeta?.alias || [],
    redirect: index5D0TyRujqLMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: index5D0TyRujqLMeta?.name ?? "partner___ja",
    path: index5D0TyRujqLMeta?.path ?? "/ja/partner",
    meta: index5D0TyRujqLMeta || {},
    alias: index5D0TyRujqLMeta?.alias || [],
    redirect: index5D0TyRujqLMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: index5D0TyRujqLMeta?.name ?? "partner___zh",
    path: index5D0TyRujqLMeta?.path ?? "/zh/partner",
    meta: index5D0TyRujqLMeta || {},
    alias: index5D0TyRujqLMeta?.alias || [],
    redirect: index5D0TyRujqLMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: indexKd2cv8vBo6Meta?.name ?? "profile___de",
    path: indexKd2cv8vBo6Meta?.path ?? "/de/profile",
    meta: indexKd2cv8vBo6Meta || {},
    alias: indexKd2cv8vBo6Meta?.alias || [],
    redirect: indexKd2cv8vBo6Meta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexKd2cv8vBo6Meta?.name ?? "profile___en",
    path: indexKd2cv8vBo6Meta?.path ?? "/en/profile",
    meta: indexKd2cv8vBo6Meta || {},
    alias: indexKd2cv8vBo6Meta?.alias || [],
    redirect: indexKd2cv8vBo6Meta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexKd2cv8vBo6Meta?.name ?? "profile___fr",
    path: indexKd2cv8vBo6Meta?.path ?? "/fr/profile",
    meta: indexKd2cv8vBo6Meta || {},
    alias: indexKd2cv8vBo6Meta?.alias || [],
    redirect: indexKd2cv8vBo6Meta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexKd2cv8vBo6Meta?.name ?? "profile___it",
    path: indexKd2cv8vBo6Meta?.path ?? "/it/profile",
    meta: indexKd2cv8vBo6Meta || {},
    alias: indexKd2cv8vBo6Meta?.alias || [],
    redirect: indexKd2cv8vBo6Meta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexKd2cv8vBo6Meta?.name ?? "profile___pl",
    path: indexKd2cv8vBo6Meta?.path ?? "/pl/profile",
    meta: indexKd2cv8vBo6Meta || {},
    alias: indexKd2cv8vBo6Meta?.alias || [],
    redirect: indexKd2cv8vBo6Meta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexKd2cv8vBo6Meta?.name ?? "profile___es",
    path: indexKd2cv8vBo6Meta?.path ?? "/es/profile",
    meta: indexKd2cv8vBo6Meta || {},
    alias: indexKd2cv8vBo6Meta?.alias || [],
    redirect: indexKd2cv8vBo6Meta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexKd2cv8vBo6Meta?.name ?? "profile___pt",
    path: indexKd2cv8vBo6Meta?.path ?? "/pt/profile",
    meta: indexKd2cv8vBo6Meta || {},
    alias: indexKd2cv8vBo6Meta?.alias || [],
    redirect: indexKd2cv8vBo6Meta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexKd2cv8vBo6Meta?.name ?? "profile___nl",
    path: indexKd2cv8vBo6Meta?.path ?? "/nl/profile",
    meta: indexKd2cv8vBo6Meta || {},
    alias: indexKd2cv8vBo6Meta?.alias || [],
    redirect: indexKd2cv8vBo6Meta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexKd2cv8vBo6Meta?.name ?? "profile___ja",
    path: indexKd2cv8vBo6Meta?.path ?? "/ja/profile",
    meta: indexKd2cv8vBo6Meta || {},
    alias: indexKd2cv8vBo6Meta?.alias || [],
    redirect: indexKd2cv8vBo6Meta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexKd2cv8vBo6Meta?.name ?? "profile___zh",
    path: indexKd2cv8vBo6Meta?.path ?? "/zh/profile",
    meta: indexKd2cv8vBo6Meta || {},
    alias: indexKd2cv8vBo6Meta?.alias || [],
    redirect: indexKd2cv8vBo6Meta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexc0AB1oOrtnMeta?.name ?? "register___de",
    path: indexc0AB1oOrtnMeta?.path ?? "/de/register",
    meta: indexc0AB1oOrtnMeta || {},
    alias: indexc0AB1oOrtnMeta?.alias || [],
    redirect: indexc0AB1oOrtnMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexc0AB1oOrtnMeta?.name ?? "register___en",
    path: indexc0AB1oOrtnMeta?.path ?? "/en/register",
    meta: indexc0AB1oOrtnMeta || {},
    alias: indexc0AB1oOrtnMeta?.alias || [],
    redirect: indexc0AB1oOrtnMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexc0AB1oOrtnMeta?.name ?? "register___fr",
    path: indexc0AB1oOrtnMeta?.path ?? "/fr/register",
    meta: indexc0AB1oOrtnMeta || {},
    alias: indexc0AB1oOrtnMeta?.alias || [],
    redirect: indexc0AB1oOrtnMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexc0AB1oOrtnMeta?.name ?? "register___it",
    path: indexc0AB1oOrtnMeta?.path ?? "/it/register",
    meta: indexc0AB1oOrtnMeta || {},
    alias: indexc0AB1oOrtnMeta?.alias || [],
    redirect: indexc0AB1oOrtnMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexc0AB1oOrtnMeta?.name ?? "register___pl",
    path: indexc0AB1oOrtnMeta?.path ?? "/pl/register",
    meta: indexc0AB1oOrtnMeta || {},
    alias: indexc0AB1oOrtnMeta?.alias || [],
    redirect: indexc0AB1oOrtnMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexc0AB1oOrtnMeta?.name ?? "register___es",
    path: indexc0AB1oOrtnMeta?.path ?? "/es/register",
    meta: indexc0AB1oOrtnMeta || {},
    alias: indexc0AB1oOrtnMeta?.alias || [],
    redirect: indexc0AB1oOrtnMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexc0AB1oOrtnMeta?.name ?? "register___pt",
    path: indexc0AB1oOrtnMeta?.path ?? "/pt/register",
    meta: indexc0AB1oOrtnMeta || {},
    alias: indexc0AB1oOrtnMeta?.alias || [],
    redirect: indexc0AB1oOrtnMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexc0AB1oOrtnMeta?.name ?? "register___nl",
    path: indexc0AB1oOrtnMeta?.path ?? "/nl/register",
    meta: indexc0AB1oOrtnMeta || {},
    alias: indexc0AB1oOrtnMeta?.alias || [],
    redirect: indexc0AB1oOrtnMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexc0AB1oOrtnMeta?.name ?? "register___ja",
    path: indexc0AB1oOrtnMeta?.path ?? "/ja/register",
    meta: indexc0AB1oOrtnMeta || {},
    alias: indexc0AB1oOrtnMeta?.alias || [],
    redirect: indexc0AB1oOrtnMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexc0AB1oOrtnMeta?.name ?? "register___zh",
    path: indexc0AB1oOrtnMeta?.path ?? "/zh/register",
    meta: indexc0AB1oOrtnMeta || {},
    alias: indexc0AB1oOrtnMeta?.alias || [],
    redirect: indexc0AB1oOrtnMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexvEaUqa6s6cMeta?.name ?? "sustainability___de",
    path: indexvEaUqa6s6cMeta?.path ?? "/de/sustainability",
    meta: indexvEaUqa6s6cMeta || {},
    alias: indexvEaUqa6s6cMeta?.alias || [],
    redirect: indexvEaUqa6s6cMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/sustainability/index.vue").then(m => m.default || m)
  },
  {
    name: indexvEaUqa6s6cMeta?.name ?? "sustainability___en",
    path: indexvEaUqa6s6cMeta?.path ?? "/en/sustainability",
    meta: indexvEaUqa6s6cMeta || {},
    alias: indexvEaUqa6s6cMeta?.alias || [],
    redirect: indexvEaUqa6s6cMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/sustainability/index.vue").then(m => m.default || m)
  },
  {
    name: indexvEaUqa6s6cMeta?.name ?? "sustainability___fr",
    path: indexvEaUqa6s6cMeta?.path ?? "/fr/sustainability",
    meta: indexvEaUqa6s6cMeta || {},
    alias: indexvEaUqa6s6cMeta?.alias || [],
    redirect: indexvEaUqa6s6cMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/sustainability/index.vue").then(m => m.default || m)
  },
  {
    name: indexvEaUqa6s6cMeta?.name ?? "sustainability___it",
    path: indexvEaUqa6s6cMeta?.path ?? "/it/sustainability",
    meta: indexvEaUqa6s6cMeta || {},
    alias: indexvEaUqa6s6cMeta?.alias || [],
    redirect: indexvEaUqa6s6cMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/sustainability/index.vue").then(m => m.default || m)
  },
  {
    name: indexvEaUqa6s6cMeta?.name ?? "sustainability___pl",
    path: indexvEaUqa6s6cMeta?.path ?? "/pl/sustainability",
    meta: indexvEaUqa6s6cMeta || {},
    alias: indexvEaUqa6s6cMeta?.alias || [],
    redirect: indexvEaUqa6s6cMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/sustainability/index.vue").then(m => m.default || m)
  },
  {
    name: indexvEaUqa6s6cMeta?.name ?? "sustainability___es",
    path: indexvEaUqa6s6cMeta?.path ?? "/es/sustainability",
    meta: indexvEaUqa6s6cMeta || {},
    alias: indexvEaUqa6s6cMeta?.alias || [],
    redirect: indexvEaUqa6s6cMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/sustainability/index.vue").then(m => m.default || m)
  },
  {
    name: indexvEaUqa6s6cMeta?.name ?? "sustainability___pt",
    path: indexvEaUqa6s6cMeta?.path ?? "/pt/sustainability",
    meta: indexvEaUqa6s6cMeta || {},
    alias: indexvEaUqa6s6cMeta?.alias || [],
    redirect: indexvEaUqa6s6cMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/sustainability/index.vue").then(m => m.default || m)
  },
  {
    name: indexvEaUqa6s6cMeta?.name ?? "sustainability___nl",
    path: indexvEaUqa6s6cMeta?.path ?? "/nl/sustainability",
    meta: indexvEaUqa6s6cMeta || {},
    alias: indexvEaUqa6s6cMeta?.alias || [],
    redirect: indexvEaUqa6s6cMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/sustainability/index.vue").then(m => m.default || m)
  },
  {
    name: indexvEaUqa6s6cMeta?.name ?? "sustainability___ja",
    path: indexvEaUqa6s6cMeta?.path ?? "/ja/sustainability",
    meta: indexvEaUqa6s6cMeta || {},
    alias: indexvEaUqa6s6cMeta?.alias || [],
    redirect: indexvEaUqa6s6cMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/sustainability/index.vue").then(m => m.default || m)
  },
  {
    name: indexvEaUqa6s6cMeta?.name ?? "sustainability___zh",
    path: indexvEaUqa6s6cMeta?.path ?? "/zh/sustainability",
    meta: indexvEaUqa6s6cMeta || {},
    alias: indexvEaUqa6s6cMeta?.alias || [],
    redirect: indexvEaUqa6s6cMeta?.redirect,
    component: () => import("/opt/app-root/src/website/pages/sustainability/index.vue").then(m => m.default || m)
  }
]