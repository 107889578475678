import {
  createPromiseClient,
  Code,
  type PromiseClient,
  ConnectError,
  type Transport,
} from '@connectrpc/connect';
import {
  Message,
  type PlainMessage,
  type ServiceType,
  toPlainMessage,
} from '@bufbuild/protobuf';
import type {
  GpRegion,
  GrpcRegionMergedResponse,
  GrpcResponse,
  IncomingDetail,
} from './types';
import { getSecondaryRegion } from './utils';

interface UniversalGrpcClientTransports {
  primaryRegion: GpRegion;
  transports?:
    | { [key in GpRegion]: Transport }
    | { [key in string]: Transport };
}

export const grpcMetadataMethodCallAsync = async <
  T extends ServiceType,
  K extends keyof T['methods'],
  I = T['methods'][K]['I'],
  O extends PlainMessage<Message> = InstanceType<T['methods'][K]['O']>,
>(
  instance: PromiseClient<ServiceType>,
  method: K,
  requestMessage: I,
  metaData: Headers | undefined,
  timeout = 10000,
): Promise<GrpcResponse<O>> => {
  try {
    const response = await instance[method].call(this, requestMessage, {
      timeoutMs: timeout,
      headers: metaData,
    });

    return {
      response: toPlainMessage(response) as O,
      error: null,
    };
  } catch (error) {
    if (error instanceof ConnectError) {
      const response = {
        response: null,
        error: {
          code: error.code,
          message: error.message,
          details: [] as IncomingDetail[],
        },
      };

      // filter all details that are of type IncomingDetail
      error.details
        .filter((d) => {
          return !(d instanceof Message);
        })
        .forEach((detail: IncomingDetail) => {
          response.error.details.push({
            ...detail,
          });
        });

      error.details
        .filter((d) => {
          return d instanceof Message;
        })
        .forEach((detail: Message) => {
          response.error.details.push({
            type: detail.getType().typeName,
            value: detail.toBinary(),
          });
        });

      return response;
    }

    return {
      response: null,
      error: {
        code: Code.Unknown,
        message: error.message,
      },
    };
  }
};

// T: the GRPC client class
// K: the method of the client class you want to execute
export default class UniversalGrpcClient<T extends ServiceType> {
  clientPrimary: PromiseClient<T>;

  clientSecondary: PromiseClient<T>;

  primaryRegion: GpRegion;

  /**
   * The UniversalGrpcClient constructor
   *
   * @param Service - the GRPC client class
   * @param opts - the primary region and the endpoints as string or the transports
   */
  constructor(
    Service: T,
    { primaryRegion, transports }: UniversalGrpcClientTransports,
  ) {
    this.primaryRegion = primaryRegion;

    if (transports != null) {
      this.clientPrimary = createPromiseClient(
        Service,
        transports[this.primaryRegion],
      );
      this.clientSecondary = createPromiseClient(
        Service,
        transports[this.secondaryRegion],
      );
    } else {
      throw new Error('no endpoints or transports provided');
    }
  }

  /**
   * Calls the API endpoint in the primary region only.
   *
   * @param method - the method of the client class you want to execute
   * @param requestMessage - the required requestMessage which is an instance of a request class I
   * @param metaData - the request metadata - in this context request headers
   * @param timeout - the timeout for the request
   * @returns a promise with the server response and the error (service error when the error comes from grpc)
   */
  executeApiCallPrimary<
    K extends keyof T['methods'],
    I = T['methods'][K]['I'],
    O extends PlainMessage<Message> = InstanceType<T['methods'][K]['O']>,
  >(
    method: K,
    requestMessage: I,
    metaData?: Headers,
    timeout?: number,
  ): Promise<GrpcResponse<O>> {
    return grpcMetadataMethodCallAsync<T, K, I, O>(
      this.clientPrimary,
      method,
      requestMessage,
      metaData,
      timeout,
    );
  }

  /**
   * Calls the API endpoint in the primary region. If no data cannot be found in the primary region,
   * call the same endpoint in the secondary region as fallback.
   *
   * @param method - the method of the client class you want to execute
   * @param requestMessage - the required requestMessage Object for the GRPC call
   * @param metaData - the request metadata - in this context request headers
   * @param timeout - the timeout for the request
   * @throws ConnectError - if both regions return an error
   * @returns a promise with the server response and the service error
   */
  async executeApiCallFallback<
    K extends keyof T['methods'],
    I = T['methods'][K]['I'],
    O extends PlainMessage<Message> = InstanceType<T['methods'][K]['O']>,
  >(
    method: K,
    requestMessage: I,
    metaData?: Headers,
    timeout?: number,
  ): Promise<GrpcResponse<O>> {
    const { error: errorPrimary, response: responsePrimary } =
      await grpcMetadataMethodCallAsync<T, K, I, O>(
        this.clientPrimary,
        method,
        requestMessage,
        metaData,
        timeout,
      );

    // if the primary region returns a response, return it
    if (responsePrimary != null) {
      return {
        response: responsePrimary,
        error: null,
      };
    }

    // if the primary region returns an error different from NotFound, return it
    if (errorPrimary.code !== Code.NotFound) {
      return {
        response: null,
        error: errorPrimary,
      };
    }

    const { error: errorSecondary, response: responseSecondary } =
      await grpcMetadataMethodCallAsync<T, K, I, O>(
        this.clientSecondary,
        method,
        requestMessage,
        metaData,
        timeout,
      );

    return {
      response: responseSecondary,
      error: errorSecondary,
    };
  }

  /**
   * Calls both api regions and merge the results
   *
   * @param method - the method of the client class you want to execute
   * @param requestMessage - the required requestMessage Object for the GRPC call
   * @param metaData - the request metadata - in this context request headers
   * @param timeout - the timeout for the request
   * @returns a promise with a wrapper object with server responses and the errors for every region
   */
  async executeApiCallMerge<
    K extends keyof T['methods'],
    I = T['methods'][K]['I'],
    O extends PlainMessage<Message> = InstanceType<T['methods'][K]['O']>,
  >(
    method: K,
    requestMessage: I,
    metaData?: Headers,
    timeout?: number,
  ): Promise<GrpcRegionMergedResponse<O>> {
    const [responsePrimary, responseSecondary] = await Promise.all([
      grpcMetadataMethodCallAsync<T, K, I, O>(
        this.clientPrimary,
        method,
        requestMessage,
        metaData,
        timeout,
      ),
      grpcMetadataMethodCallAsync<T, K, I, O>(
        this.clientSecondary,
        method,
        requestMessage,
        metaData,
        timeout,
      ),
    ]);

    return {
      [this.primaryRegion]: responsePrimary,
      [this.secondaryRegion]: responseSecondary,
    } as GrpcRegionMergedResponse<O>;
  }

  /**
   * Calls a specific API region
   *
   * @param region - the API region
   * @param method - the method of the client class you want to execute
   * @param requestMessage - the required requestMessage Object for the GRPC call
   * @param metaData - the request metadata - in this context request headers
   * @param timeout - the timeout for the request
   * @returns a promise with a wrapper object with server responses and the errors for the spefied region
   */
  async executeApiCall<
    K extends keyof T['methods'],
    I = T['methods'][K]['I'],
    O extends PlainMessage<Message> = InstanceType<T['methods'][K]['O']>,
  >(
    region: GpRegion,
    method: K,
    requestMessage: I,
    metaData?: Headers,
    timeout?: number,
  ): Promise<GrpcResponse<O>> {
    let client: PromiseClient<ServiceType> = null;

    if (region === this.primaryRegion) {
      client = this.clientPrimary;
    } else if (region === this.secondaryRegion) {
      client = this.clientSecondary;
    }

    if (client == null) throw new Error('unknown grpc region!');

    return grpcMetadataMethodCallAsync<T, K, I, O>(
      client,
      method,
      requestMessage,
      metaData,
      timeout,
    );
  }

  get secondaryRegion() {
    return getSecondaryRegion(this.primaryRegion);
  }
}
