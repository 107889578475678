import { Scope } from '@sentry/vue';
import { GP_ACCESS_TOKEN_COOKIE } from '~/plugins/auth/constants';
import type {
  GpRegion,
  GrpcRegionMergedData,
} from '~/plugins/grpc/universal/types';
import {
  convertGpRegionToGrpcRegion,
  convertGrpcRegionToGpRegion,
} from '~/plugins/grpc/universal/utils';
import { Code } from '@connectrpc/connect';
import type { Customer } from 'gportal-grpc/gportal/api/user/v1/customer_pb';
import type { User } from 'gportal-grpc/gportal/api/user/v1/user_pb';
import {
  ChangeCurrencyRequest,
  ChangePreferredRegionRequest,
  CreateCustomerRequest,
  GetCustomerRequest,
  GetUserRequest,
} from 'gportal-grpc/gportal/api/user/v1/requests_pb';
import { type PlainMessage } from '@bufbuild/protobuf';

export const useAuthStore = defineStore('auth', () => {
  const { $sentry, $auth } = useNuxtApp();
  const $grpcUser = useGrpcUser();
  const oidcStore = useOidcStore();
  const currencyStore = useCurrencyStore();

  const authTokenCookie = useCookie(GP_ACCESS_TOKEN_COOKIE, {
    secure: true,
    httpOnly: false,
  });
  const webinterfaceRegionCookie = useCookie('webinterface_region');

  const state = reactive({
    user: null as PlainMessage<User> | null,
    activeRegion: null as GpRegion | null,
    customers: {
      EUR: null as PlainMessage<Customer> | null,
      INT: null as PlainMessage<Customer> | null,
    } as GrpcRegionMergedData<PlainMessage<Customer>>,
    isAuthenticated: false,
    isUserLoading: true,
    serviceError: null as Error | null,
  });
  const activeCustomer = computed(() =>
    state.activeRegion != null ? state.customers[state.activeRegion] : null,
  );
  const hasAlternateRegions = computed(
    () => Object.values(state.customers).length > 1,
  );

  const setCustomerCurrencyStore = (region: GpRegion) => {
    const customerWallet = state.customers[region].wallet;
    if (customerWallet != null) {
      currencyStore.currency = customerWallet.currencyCode;
    }
  };

  const getAuthorizationMetadata = (): Headers => {
    let accessToken: string;

    // there are multiple sources to get an access token
    if (
      process.client &&
      $auth != null &&
      $auth.storedTokenSet != null &&
      $auth.storedTokenSet.access_token != null
    ) {
      accessToken = $auth.storedTokenSet.access_token;
    } else if (authTokenCookie.value != null) {
      accessToken = authTokenCookie.value;
    } else if (oidcStore.accessToken != null) {
      accessToken = oidcStore.accessToken;
    }

    const metaData = new Headers();
    metaData.set('authorization', `Bearer ${accessToken}`);

    return metaData;
  };

  const setInitCustomerRegion = () => {
    const { primaryRegion } = $grpcUser;
    // prefer the webinterface region, since updating the user region over API call is only effective on token refresh
    const webinterfaceRegion = convertLegacyToGpRegion(
      webinterfaceRegionCookie.value,
    );

    if (
      webinterfaceRegion != null &&
      state.customers[webinterfaceRegion] != null
    ) {
      state.activeRegion = webinterfaceRegion;
      setCustomerCurrencyStore(webinterfaceRegion);
      return;
    }

    // as fallback now pick the preferred region
    const userRegion = convertGrpcRegionToGpRegion(
      state.user.settings.preferredRegion,
    );

    if (userRegion != null && state.customers[userRegion] != null) {
      state.activeRegion = userRegion;
      setCustomerCurrencyStore(userRegion);
      return;
    }

    // as final fallback set the primary region as region
    if (state.customers[primaryRegion] != null) {
      state.activeRegion = primaryRegion;
      setCustomerCurrencyStore(primaryRegion);
    }
  };

  const fetchUser = async () => {
    const metadata = getAuthorizationMetadata();

    const { response: userResponse, error } =
      await $grpcUser.executeApiCallPrimary(
        'getUser',
        new GetUserRequest(),
        metadata,
        5000,
      );

    if (error == null && userResponse != null) {
      state.user = userResponse.user;
      state.isUserLoading = false;
    } else {
      // there are two scenarios, either the api call fails then executeApiCallPrimary throws an error
      // or if we do not have any user, throw a generic error -> the session cannot be established
      state.user = null;
      state.isUserLoading = false;
      // throw error
      throw error;
    }
  };

  const createCustomer = async (region: GpRegion) => {
    const metadata = getAuthorizationMetadata();

    const createCustomerRequest = new CreateCustomerRequest();
    let currency: string | null = null;

    // in FE, pick currently selected currency, if it is available for the region
    if (
      currencyStore.currency &&
      SELECTABLE_CUSTOMER_CURRENCIES[region].some(
        (c) => c === currencyStore.currency,
      )
    ) {
      currency = currencyStore.currency;
    }

    // final fallback, pick default region currency
    if (currency == null) {
      currency = SELECTABLE_CUSTOMER_CURRENCIES[region][0];
    }

    createCustomerRequest.currency = currency;
    const { error, response: createCustomerResponse } =
      await $grpcUser.executeApiCall(
        region,
        'createCustomer',
        createCustomerRequest,
        metadata,
      );

    if (error == null && createCustomerResponse != null) {
      if (createCustomerResponse.customer == null) {
        throw new Error('Response of CreateCustomerRequest was empty.');
      }

      state.customers[region] = createCustomerResponse.customer;
      state.activeRegion = region;

      // setting currency in store is not necessary since we already use the store currency to create the customer
    } else {
      throw error;
    }
  };

  const fetchCustomers = async () => {
    const metadata = getAuthorizationMetadata();

    const responses = await $grpcUser.executeApiCallMerge(
      'getCustomer',
      new GetCustomerRequest(),
      metadata,
      5000,
    );

    const { primaryRegion, secondaryRegion } = $grpcUser;

    if (responses.EUR.error != null && responses.INT.error != null) {
      // if no customer cannot be found, create customer in primary region instead
      if (
        responses.EUR.error.code === Code.NotFound &&
        responses.INT.error.code === Code.NotFound
      ) {
        $sentry.captureMessage('New customer found, create customer.');

        await createCustomer(primaryRegion);
        return;
      }

      // TODO: handle one region not found and the other timed out
      const scope = new Scope();
      scope.setExtra('eur-error-code', responses.EUR.error.code);
      scope.setExtra('eur-error-message', responses.EUR.error.message);
      scope.setExtra('int-error-code', responses.INT.error.code);
      scope.setExtra('int-error-message', responses.INT.error.message);
      scope.setExtra('primary-region', primaryRegion);
      $sentry.captureException(
        'Both regions do not have any customer and their API returns were invalid.',
        scope,
      );
      // this is a unexpected error, throw the error
      throw new Error(
        'Both regions do not have any customer and their API returns were invalid.',
      );
    }

    // if the user has at least one customer - set those customers in store
    if (responses.EUR.response != null || responses.INT.response != null) {
      if (responses[primaryRegion].response != null) {
        state.customers[primaryRegion] =
          responses[primaryRegion].response.customer;
      } else {
        state.customers[primaryRegion] = null;
      }

      if (responses[secondaryRegion].response != null) {
        state.customers[secondaryRegion] =
          responses[secondaryRegion].response.customer;
      } else {
        state.customers[secondaryRegion] = null;
      }

      if (responses.EUR.response != null && responses.INT.response != null) {
        // if the user has multiple customers, set customer in the user region as active
        setInitCustomerRegion();
      } else {
        // set the active region where the corresponding response was found
        const regionWithData = Object.keys(state.customers).find(
          (region: GpRegion) => state.customers[region] != null,
        );
        state.activeRegion = regionWithData as GpRegion;
        setCustomerCurrencyStore(regionWithData as GpRegion);
      }
    }
  };

  const handleUserRegistration = async () => {
    const { primaryRegion } = $grpcUser;

    const metadata = getAuthorizationMetadata();
    const { response, error } = await $grpcUser.executeApiCallPrimary(
      'getCustomer',
      new GetCustomerRequest(),
      metadata,
    );

    if (error != null && error.code === Code.NotFound) {
      createCustomer($grpcUser.primaryRegion);
    } else if (response != null) {
      state.customers[primaryRegion] =
        response != null ? response.customer : null;
      setInitCustomerRegion();
    } else {
      throw error;
    }
  };

  const changeUserPreferredRegion = async (region: GpRegion) => {
    const metaData = getAuthorizationMetadata();

    const changeUserPreferredRegionRequest = new ChangePreferredRegionRequest();
    changeUserPreferredRegionRequest.newRegion = region;
    const { error } = await $grpcUser.executeApiCallPrimary(
      'changePreferredRegion',
      changeUserPreferredRegionRequest,
      metaData,
    );

    if (error == null) {
      state.user.settings.preferredRegion = convertGpRegionToGrpcRegion(region);
      state.activeRegion = region;
      setCustomerCurrencyStore(region);
    } else {
      throw error;
    }
  };

  const changeActiveCustomerCurrency = async (
    currency: string,
    source: 'switcher' | 'registration',
  ) => {
    const metaData = getAuthorizationMetadata();

    const changeCurrencyRequest = new ChangeCurrencyRequest();
    changeCurrencyRequest.newCurrency = currency;
    const { response, error } = await $grpcUser.executeApiCall(
      state.activeRegion,
      'changeCurrency',
      changeCurrencyRequest,
      metaData,
    );

    if (error == null) {
      state.customers[state.activeRegion].wallet = response.wallet;

      currencyStore.currency = currency;
      currencyStore.preferUserDecision = source === 'switcher';
    } else {
      throw error;
    }
  };

  return {
    ...toRefs(state),
    activeCustomer,
    hasAlternateRegions,
    fetchUser,
    fetchCustomers,
    handleUserRegistration,
    createCustomer,
    changeUserPreferredRegion,
    changeActiveCustomerCurrency,
  };
});
