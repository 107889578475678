import { GpRegion } from './types';
import { Region } from 'gportal-grpc/gportal/type/v1/region_pb';

export const getSecondaryRegion = (primaryRegion: GpRegion) =>
  Object.keys(GpRegion).find((r) => r !== primaryRegion) as GpRegion;

export const convertGrpcRegionToGpRegion = (region: Region) => {
  switch (region) {
    case Region.EUR:
      return GpRegion.EUR;
    case Region.INT:
      return GpRegion.INT;
    default:
      return null;
  }
};

export const convertGpRegionToGrpcRegion = (region: GpRegion) => {
  switch (region) {
    case GpRegion.EUR:
      return Region.EUR;
    case GpRegion.INT:
      return Region.INT;
    default:
      return null;
  }
};

export default { getSecondaryRegion };
