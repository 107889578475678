import { createGrpcWebTransport } from '@connectrpc/connect-web';
import type { GpRegion } from './grpc/universal/types';
import { getSecondaryRegion } from './grpc/universal/utils';

export default defineNuxtPlugin(() => {
  const { public: publicConfig } = useRuntimeConfig();
  const { grpc } = publicConfig;
  const primaryRegion = grpc.primaryRegion as GpRegion;
  const secondaryRegion = getSecondaryRegion(grpc.primaryRegion as GpRegion);

  const primaryTransport = createGrpcWebTransport({
    baseUrl: grpc.endpoints[primaryRegion],
    useBinaryFormat: true,
    credentials: 'include',
  });

  const secondaryTransport = createGrpcWebTransport({
    baseUrl: grpc.endpoints[secondaryRegion],
    useBinaryFormat: true,
    credentials: 'include',
  });

  return {
    provide: {
      primaryTransport,
      secondaryTransport,
    },
  };
});
