// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file gportal/api/user/v1/customer.proto (package gportal.api.user.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Date } from "../../../type/v1/date_pb.js";
import { Money } from "../../../type/v1/money_pb.js";

/**
 * @generated from message gportal.api.user.v1.Customer
 */
export class Customer extends Message<Customer> {
  /**
   * GPORTAL Customer ID
   *
   * @generated from field: uint64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: string first_name = 2;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 3;
   */
  lastName = "";

  /**
   * @generated from field: gportal.type.v1.Date birthday = 4;
   */
  birthday?: Date;

  /**
   * @generated from field: gportal.type.v1.Money wallet = 5;
   */
  wallet?: Money;

  /**
   * @generated from field: bool is_allowed_to_switch_currency = 6;
   */
  isAllowedToSwitchCurrency = false;

  /**
   * @generated from field: uint32 all_clouds_count = 7;
   */
  allCloudsCount = 0;

  /**
   * @generated from field: bool is_admin = 8;
   */
  isAdmin = false;

  /**
   * @generated from field: bool is_supporter = 9;
   */
  isSupporter = false;

  constructor(data?: PartialMessage<Customer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "gportal.api.user.v1.Customer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "birthday", kind: "message", T: Date },
    { no: 5, name: "wallet", kind: "message", T: Money },
    { no: 6, name: "is_allowed_to_switch_currency", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "all_clouds_count", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 8, name: "is_admin", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "is_supporter", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Customer {
    return new Customer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Customer {
    return new Customer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Customer {
    return new Customer().fromJsonString(jsonString, options);
  }

  static equals(a: Customer | PlainMessage<Customer> | undefined, b: Customer | PlainMessage<Customer> | undefined): boolean {
    return proto3.util.equals(Customer, a, b);
  }
}

