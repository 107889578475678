import {
  definePayloadPlugin,
  definePayloadReducer,
  definePayloadReviver,
} from '#app';

// https://github.com/Rich-Harris/devalue/blob/master/src/uneval.js
export default definePayloadPlugin(() => {
  definePayloadReducer(
    'Uint8Array',
    (data) => data instanceof Uint8Array && new TextDecoder().decode(data),
  );
  definePayloadReviver('Uint8Array', (data: string) =>
    new TextEncoder().encode(data),
  );
});
