import {
  init,
  captureMessage,
  captureEvent,
  captureException,
  lastEventId,
  addIntegration,
  browserTracingIntegration,
} from '@sentry/vue';

export default defineNuxtPlugin({
  name: 'sentry-client',
  setup: (nuxtApp) => {
    const { public: publicConfig } = useRuntimeConfig();
    const { sentry: config } = useAppConfig();
    const authStore = useAuthStore();
    const router = useRouter();

    if (!publicConfig.sentry.dsn) {
      return {
        provide: {
          sentry: null,
        },
      };
    }

    init({
      app: nuxtApp.vueApp,
      dsn: publicConfig.sentry.dsn,
      environment: process.env.NODE_ENV.toUpperCase(),
      integrations: [browserTracingIntegration({ router })],
      release: config.release,
      logErrors: true,
      attachProps: true,
      attachStacktrace: true,
      trackComponents: true,
      tracesSampleRate: config.tracesSampleRate, // Change in prod
      beforeSend(event) {
        if (authStore.user) {
          event.user = {
            id: authStore.user.id,
            email: authStore.user.email,
            username: authStore.user.username,
          };
        }

        return event;
      },
    });

    onNuxtReady(async () => {
      try {
        const { replayIntegration } = await import('@sentry/vue');
        addIntegration(
          replayIntegration({
            maxReplayDuration: 5 * 60 * 1000,
            sessionSampleRate: 0,
            errorSampleRate: config.replaysSampleRate,
          }),
        );
      } catch (error) {
        captureException(error);
      }
    });

    nuxtApp.hook('app:error', (err) => {
      captureException(err);
    });

    return {
      provide: {
        sentry: {
          lastEventId,
          captureMessage,
          captureEvent,
          captureException,
          addIntegration,
        },
      },
    };
  },
});
