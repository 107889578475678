<template>
  <nuxt-layout name="default">
    <div class="error-page">
      <div class="container pt-16">
        <h1 v-if="error.statusCode === 404">
          {{ t('general/error/alternative_title') }}
        </h1>
        <h1 v-else>{{ t('general/non_special_texts/error') }}</h1>
        <p v-if="error.statusCode === 500">
          {{ error.message || t('general/error/text') }}
        </p>
        <p v-else>
          {{ t('general/error/alternative_subtitle') }}
        </p>
        <p v-if="sentryEvent">
          {{
            t('general/error/sentry_event', {
              id: sentryEvent,
            })
          }}
        </p>
        <dev-only v-if="error.stack">
          <pre class="bg-midnight-2 p-4">{{ error.stack }}</pre>
        </dev-only>
        <button class="btn btn-primary" type="button" @click="handleError">
          <fa-icon :icon="faArrowLeft" />
          {{ t('frontend/back') }}
        </button>
      </div>
    </div>
  </nuxt-layout>
</template>

<script setup lang="ts">
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import type { NuxtError } from '#app';

defineProps<{
  error: NuxtError;
}>();

const { $sentry } = useNuxtApp();

const sentryEvent = computed(() =>
  $sentry ? $sentry.lastEventId() || null : null,
);

const { t, locale } = useI18n();

const handleError = () => {
  clearError({ redirect: `/${locale.value}` });
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/mixins/breakpoints';
@import 'gportal-theme/scss/breakpoints.scss';

.error-page {
  background: url(/sc/images/svgs/spaceship.svg) no-repeat;
  background-position: bottom left;
  background-size: 100%;
  // can cause issues in iOS Safari, because buggy implementation of vh units
  min-height: 110vh;
  background-position: 30% 80%;
  background-repeat: no-repeat;
  background-size: 350%;
  width: 100%;
  max-width: unset;

  @include media-breakpoint-up(md) {
    background-size: 200%;
    background-position: 50% 100%;
  }

  @include media-breakpoint-up(xl) {
    background-size: 110%;
    background-position: bottom;
  }
}
</style>
